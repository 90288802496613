import React, { useEffect, useState } from "react";
import ContactForm from "../company/contact/form/ContactForm";
import { BiSolidUser } from "react-icons/bi";
import { PiVideoFill } from "react-icons/pi";
import { RiBook2Line } from "react-icons/ri";
import { FaBlog, FaRegCalendar } from "react-icons/fa";
import { LuCopy } from "react-icons/lu";
import moment from "moment";
import { Divider } from "antd";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button, Input } from "@material-tailwind/react";

const ResourceDetailsItems = (props) => {
  const [copied, setCopied] = useState(false);
  const currentUrl = window.location.href;
  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 30000); // 60 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount or copied state change
    }
  }, [copied]);
  return (
    <>
      <div className="outerContainer resource-details-items-outer-container">
        <div className="innerContainer">
          <div className="resource-details-items-container">
            <div className="resource-details-items-content-container">
              <div className="resource-details-items-content-image">
                <img
                  src={props.content.imageURL}
                  alt="resources image"
                  className={
                    props.content.type === "Webinar" &&
                    "resource-details-items-content-image-webinar"
                  }
                />
              </div>

              <div className="resource-details-items-content">
                <div className="resource-details-items-content-header">
                  <div className="resource-details-items-content-title">
                    {props.content.title}
                  </div>
                  <div className="resource-details-items-info">
                    <div className="resource-details-items-info-type">
                      {props.content.type === "Blog" ? (
                        <FaBlog size={15} />
                      ) : props.content.type === "Case study" ? (
                        <RiBook2Line size={15} />
                      ) : (
                        <PiVideoFill size={15} />
                      )}
                      {props.content.type}
                    </div>
                    <Divider type="vertical" />
                    <div className="resource-details-items-info-date">
                      <FaRegCalendar size={15} />

                      {moment
                        .unix(props.content.publishedDate)
                        .format("MMMM Do YYYY")}
                    </div>
                    <Divider type="vertical" />

                    {props.content.author && (
                      <div className="resource-details-items-info-author">
                        <BiSolidUser size={15} />
                        {props.content.author}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: props.content.content }}
                  className="resource-details-items-content-data"
                ></div>
                {props.content.type === "Webinar" &&
                  props.content.registrationURL && (
                    <div className="resource-details-items-content-reg-link">
                      <a
                        href={props.content.registrationURL}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#007bff" }}
                      >
                        <button className="button">Register Now</button>
                      </a>
                    </div>
                  )}
                <Divider />

                <div className="resource-details-items-content-social-share">
                  Share this article:
                  <div className="resource-details-items-content-social-share-btn">
                    <FacebookShareButton
                      url={currentUrl}
                      quote={props.content.title}
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={currentUrl}
                      title={props.content.title}
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      url={currentUrl}
                      title={props.content.title}
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                    <WhatsappShareButton
                      url={currentUrl}
                      title={props.content.title}
                      separator=":: "
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                  </div>
                </div>
                <div className="copy-link-container">
                  <input value={currentUrl} />
                  <CopyToClipboard
                    text={currentUrl}
                    onCopy={() => setCopied(true)}
                  >
                    <Button type="primary">
                      {!copied ? "Copy" : "Copied"}
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>

            {/* Optional sidebar */}
            {/* <div className="resource-details-items-sidebar">
              <div className="contact-details-container-form-container">
                {props.content.type !== "Webinar" ? (
                  <>
                    <div className="contact-details-container-form-container-main-title sectionMainTitle">
                      Get In Touch
                    </div>
                    <div className="contact-details-container-form-container-title">
                      Talk to an expert
                    </div>
                    <div className="contact-details-container-form-container-description para">
                      Interested in discovering how iNextLabs Generative-AI
                      solutions can elevate your business? Fill out the form
                      below to connect with an expert and explore the
                      possibilities.
                    </div>
                  </>
                ) : props.content?.status === "current" ||
                  props.content?.status === "upcoming" ? (
                  <>
                    <div className="contact-details-container-form-container-main-title sectionMainTitle">
                      Get In Touch
                    </div>
                    <div className="contact-details-container-form-container-title">
                      Talk to an expert
                    </div>
                    <div className="contact-details-container-form-container-description para">
                      Interested in discovering how iNextLabs Generative-AI
                      solutions can elevate your business? Fill out the form
                      below to connect with an expert and explore the
                      possibilities.
                    </div>
                  </>
                ) : (
                  <>
                    <div className="contact-details-container-form-container-title">
                      Watch Video
                    </div>
                    <div className="para">
                      Watch This Webinar to Propel Your Business Forward with AI
                    </div>
                  </>
                )}
                <ContactForm content={props.content.type} />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResourceDetailsItems;
