import React from "react";
import Meta from "../../Meta";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import ProductsBanner from "../banner/ProductsBanner";
import CounterStats from "../../stats-counter/Stats-counter";
import InnerPageValueProps from "../../innerpageswimlane/InnerPageValueProps";
import ValueProposition from "../../value-proposition/ValueProposition";
import AccordionSection from "../../accordion/Accordion";
import Footer from "../../footer/Footer";
import { inFlowEnterpriseContent } from "../inflow-enterprise/inFlowEnterpriseContent";
import InfoTable from "../../home-info-table/InfoTable";
import DiscoveryWorkshop from "../../discovery-workshop/DiscoveryWorkshop";
import { GenerativeAiInfographic } from "../../generative-ai/GenerativeAi";

const InFlowEnterprise = () => {
  return (
    <>
      <Meta
        title={inFlowEnterpriseContent.meta.title}
        description={inFlowEnterpriseContent.meta.description}
        image={inFlowEnterpriseContent.meta.image}
        locale={inFlowEnterpriseContent.meta.locale}
        siteName={inFlowEnterpriseContent.meta.siteName}
        type={inFlowEnterpriseContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <ProductsBanner content={inFlowEnterpriseContent.banner}/>
      <CounterStats content={inFlowEnterpriseContent.stats} />
      <InnerPageValueProps content={inFlowEnterpriseContent.valueProps} />
      <ValueProposition content={inFlowEnterpriseContent.ValueProposition} />
      <InfoTable />
      <GenerativeAiInfographic />
      <DiscoveryWorkshop />

      <AccordionSection content={inFlowEnterpriseContent.FAQ} />
      <Footer />
    </>
  );
};

export default InFlowEnterprise;
