import React from "react";
import Divider from "../Divider";

const PartnersLogo = (props) => {
  return (
    <div>
      <div className="outerContainer partners-logo-outer-container">
        <div className="innerContainer partners-logo-inner-container">
          <div className="partners-logo-container">
            <h2 className="sectionTitle">{props.content.title}</h2>
            <Divider />
            <div className="logo">
              {props.content.logos.map((logo) => (
                <img key={logo.id} src={logo.imageURL} alt="logo" />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnersLogo;
