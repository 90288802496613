import Divider from "../../../Divider";
import React from "react";

const AboutStatement = () => {
  return (
    <div>
      <div className="outerContainer">
        <div className="innerContainer">
          <div className="about-statement-container">
            <h2 className="about-statement-container-title">About</h2>
            <div className="about-statement-container-description">
              <p>
                <strong>iNextLabs</strong> is an all-in-one AI platform that
                leverages AI for enterprises to optimize marketing, support, and
                operations, boosting ROI, customer satisfaction, and efficiency.
              </p>
              <p>
                Founded in December 2020 by a team with 50 years of combined
                experience, iNextLabs aims to revolutionize customer
                interactions through AI. Our mission is to help businesses
                harness AI's full potential to redefine customer experiences.
              </p>
              <p>
                We quickly gained recognition as a leader in the AI domain by
                developing innovative solutions that integrate AI technologies
                into various customer interactions. Our transformative
                offerings, powered by Conversational and Generative-AI, include
                natural language processing, chatbots, and creative content
                generation, bringing AI to the forefront of customer engagement.
              </p>
              <p>
                Our commitment to excellence and continuous innovation ensures
                we stay at the forefront of technology, delivering exceptional
                value to our clients and pushing the boundaries of what AI can
                achieve in customer experience.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="outerContainer">
        <h2 className="about-missionandvision-section-title sectionTitle">
          Our Vision and Mission
        </h2>
        <Divider />
        <div className="innerContainer">
          <div className="about-statement-container">
            <h2 className="about-statement-container-title">Mission</h2>
            <div className="about-statement-container-description">
              <p>
                At iNextLabs, our mission is to empower businesses to redefine
                customer experiences through advanced AI solutions. We are
                dedicated to leveraging artificial intelligence to create
                innovative tools that enhance engagement, streamline operations,
                and drive growth, making interactions more meaningful and
                efficient.
              </p>
            </div>
          </div>
        </div>

        <div className="innerContainer">
          <div className="about-statement-container">
            <h2 className="about-statement-container-title">Vision</h2>
            <div className="about-statement-container-description">
              <p>
                Our vision is to be the leading partner in AI technology,
                setting new standards for personalized and efficient service. We
                aim to drive the seamless integration of AI into business
                operations, continuously advancing AI solutions to help
                businesses thrive.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutStatement;
