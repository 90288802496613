import React from "react";

const BuisnessStatement = () => {
  return (
    <>
      <div className="outerContainer">
        <div className="buisness-statement-container">
          <div className="buisness-statement-main-title">
            Elevate your operations with our Generative AI solutions seamlessly
            fusing innovation and automation for enhanced business efficiency.
          </div>
        </div>
      </div>
    </>
  );
};

export default BuisnessStatement;
