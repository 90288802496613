import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to get route parameters
import Header from '../../header/Header';
import Filler from '../../header/Filler';
import Footer from '../../footer/Footer';
import { newsContent } from './newsContent';
import NewsDetailsItems from './NewsDetailsItem';
import NewsBanner from './NewsBanner';

const NewsDetails = () => {
    const { id } = useParams(); // Use useParams to get the route parameter
    const selectedNewsUrl = id;
    const newsItems = newsContent.newsItems;
    const selectedNews = newsItems.find((news) => news.url === selectedNewsUrl);
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on page load
    }, []);
    return (
        <div>
            <Header />
            <Filler />
            {/* <NewsBanner content={selectedNews}/> */}
            <NewsDetailsItems content={selectedNews} />
            <Footer/>
        </div>
    );
}

export default NewsDetails;
