import React, { useState } from "react";
import Header from "../header/Header";
import Meta from "../Meta";
import Filler from "../header/Filler";
import ResouresBanner from "./banner/ResourcesBanner";
import Footer from "../footer/Footer";
import { resourcesContent } from "./resourcesContent";
import { webinarContent } from "./webinarContent";
import { Link } from "react-router-dom";
import { PiVideoFill } from "react-icons/pi";
import { BiTimeFive } from "react-icons/bi";
import moment from "moment";

import { Button } from "@material-tailwind/react";

const Webinar = () => {
  const sortedWebinarItems = webinarContent.webinarItems.sort((a, b) => {
    return b.publishedDate - a.publishedDate;
  });
  return (
    <div>
      <Meta
        title={webinarContent.meta.title}
        description={webinarContent.meta.description}
        image={webinarContent.meta.image}
        locale={webinarContent.meta.locale}
        siteName={webinarContent.meta.siteName}
        type={webinarContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <ResouresBanner content={webinarContent.banner} />

      <div className="outerContainer">
        <div className="innerContainer">
          <div className="resourcesListContainer">
            {sortedWebinarItems.map((webinar, index) => {
              return <WebinarItem key={index} content={webinar} />;
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Webinar;

const WebinarItem = (props) => {
  const [isOpen, setIsOpen] = useState(false); // Define isOpen and setIsOpen

  return (
    <>
      {/* <Link
        className="resourceLink"
        to={props.content.url ? `/resources/${props.content.url}` : `/`}
      > */}
      <div className="resources-item-container">
        <div className="resource-card resources-webinar-card">
          <div className="resource-card-image resources-webinar-card-image">
            <img
              className="img"
              src={props.content.imageURL}
              alt="resource Image"
            />
            {props.content.industry && (
              <div className="resource-industry">{props.content.industry}</div>
            )}
          </div>
          <div className="resource-content">
            <div className="resource-content-header">
              <h5 className="resource-category resource-text-success">
                <PiVideoFill />
                {props.content.type}
              </h5>
              {props.content.country && (
                <div className="resource-content-country">
                  <p className="para">{props.content.country}</p>
                </div>
              )}
            </div>
            <div className="resource-card-caption-container">
              <h2 className="resource-card-caption">{props.content.title}</h2>
            </div>
            <div className="resource-card-footer">
              <div className="resource-footer-button ">
                <Link
                  to={
                    props.content.url
                      ? `/resources/webinars/${props.content.url}`
                      : `/resources/webinars`
                  }
                >
                  <Button>Read More</Button>
                </Link>
              </div>
              {props.content.status === "upcoming" ? (
                <div className="para">To be announced</div>
              ) : (
                <>
                  <div className="resource-footer-time">
                    <BiTimeFive size={18} />{" "}
                    <span>
                      {moment
                        .unix(props.content.publishedDate)
                        .format("MMM Do YYYY")}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
