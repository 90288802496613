import React, { useState } from "react";

const NewsLetter = () => {
  const [email, setEmail] = useState("");

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can implement the logic to handle form submission, like sending a request to your backend or directly to Mailchimp API
    console.log("Form submitted with email:", email);
    // Clear input field after submission
    setEmail("");
  };
  return (
    <>
      <div className="newsletter-container">
        <div id="mc_embed_shell">
            <p className="newsletter-container-text">Subscribe to our Newsletter</p>
          <div id="mc_embed_signup" className="newsletter-form-control">
            <form onSubmit={handleSubmit}>
              <div id="mc_embed_signup_scroll newsletter-form-control">
                <div className="mc-field-group " >
                  <input
                    type="email"
                    name="EMAIL"
                    className="newsletter-input"
                    id="mce-EMAIL"
                    placeholder="Enter your mail id"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div hidden>
                  <input type="hidden" name="tags" value="14370389,14375993" />
                </div>
                <div className="clear">
                  <input
                    type="submit"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="button newsletter-btn"
                    value="Subscribe"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsLetter;
