import { MenuOutlined } from "@ant-design/icons";
import React, { useState } from "react"
import HeaderBurgerMenuItems from "./HeaderBurgerMenuItems";

function HeaderMenuBurger() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!isOpen);

  function handleClick() {
    setIsExpanded(!isExpanded);
    const menuBurger = document.querySelector(".burger-menu-button");
    menuBurger.textContent = isExpanded ? "☰" : "✕";
  }

  return (
    <>
      <div className="burger-menu-container">
        <div className="burger-menu-background"></div>
        <div
          className={`burger-menu-button ${isExpanded ? "fs" : ""}`}
          onClick={handleClick}
        >
          <MenuOutlined />
        </div>
        <div className={`burger-menu-items-container ${isExpanded ? "fs" : ""}`}>
          <div className="burger-menu-items">
          <HeaderBurgerMenuItems/>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderMenuBurger;
