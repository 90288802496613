import React from "react";
import Header from "../header/Header";
import Filler from "../header/Filler";
import { resourcesContent } from "./resourcesContent";
import ResourceDetailsItems from "./ResourceDetailsItems";
import { useParams } from "react-router-dom"; // Import useParams to get route parameters
import Footer from "../footer/Footer";
import ResourceBanner from "./ResourceBanner";
import Meta from "../Meta";
import { libraryContent } from "./library/libraryContent";

const ResourceDetails = () => {
  const { id } = useParams();
  const selectedResourceUrl = id;
  const resourceItems = [...resourcesContent.resourceItems];
  const guideItems = libraryContent.libraryItems.filter(
    (item) => item.type === "Guide"
  );
  const updatedResourceItems = [...resourceItems, ...guideItems];

  const selectedResource = updatedResourceItems.find(
    (resource) => resource.url === selectedResourceUrl
  );
  return (
    <div>
      <Header />
      <Filler />
      {/* <ResourceBanner content={selectedResource} /> */}
      <ResourceDetailsItems content={selectedResource} />
      <Footer />
    </div>
  );
};

export default ResourceDetails;
