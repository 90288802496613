import React, { useState } from "react";
import { IoIosCheckmark } from "react-icons/io";
import { FaRegUser } from "react-icons/fa";
import { BiConversation } from "react-icons/bi";
import Divider from "../Divider";
import SignUpModal from "../modal/SignUpModal";
import { Button } from "@material-tailwind/react";

const PriceCard = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <div className="outerContainer price-card-section-outer-container">
        <div className="innerContainer">
          <div className="priceCard">
            <div className="priceCard-header">
              <h2 className="sectionTitle">inFlow Features & Modules</h2>
              <p className="para center">
                Select the right inFlow plan for your business needs and growth
              </p>
              <Divider />
            </div>
            <div className="priceCard-content">
              <div className="priceCard-content-card card1">
                <div className="priceCard-content-card-header">
                  <h2>Starter</h2>
                  <p>
                    <FaRegUser /> 2 Users
                  </p>
                  <p>
                    <BiConversation /> 1000 Conversation
                  </p>
                </div>
                {/* <div className="priceCard-content-card-price">
                  <h1>
                    $99<span>/mo</span>
                  </h1>
                </div> */}
                <div className="priceCard-content-card-button">
                  <Button
                    className="button"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    Choose Plan
                  </Button>
                </div>
                <h3>Features:</h3>
                <div className="priceCard-content-card-features">
                  <ul>
                    <li>
                      <IoIosCheckmark size={30} />
                      Broadcast
                    </li>
                    <li>
                      <IoIosCheckmark size={30} />
                      Responses
                    </li>
                    <li>
                      <IoIosCheckmark size={30} />
                      Contacts
                    </li>
                    <li>
                      <IoIosCheckmark size={30} />
                      Basic Assistant
                    </li>
                  </ul>
                </div>
                {/* <div className="priceCard-content-card-button">
                  <button>Choose Plan</button>
                </div> */}
              </div>
              <div className="priceCard-content-card card2">
                <div className="priceCard-content-card-header">
                  <h2>Standard</h2>
                  <p>
                    <FaRegUser /> 3 Users
                  </p>
                  <p>
                    <BiConversation /> 3000 Conversation
                  </p>
                </div>
                {/* <div className="priceCard-content-card-price">
                  <h1>
                    $199<span>/mo</span>
                  </h1>
                </div> */}
                <div className="priceCard-content-card-button">
                  <Button
                    className="button"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    Choose Plan
                  </Button>
                </div>
                <h3>Features:</h3>
                <div className="priceCard-content-card-features">
                  <ul>
                    <li>
                      <IoIosCheckmark size={30} />
                      Everything in Starter
                    </li>
                    <li>
                      <IoIosCheckmark size={30} />
                      WhatsApp Catalog
                    </li>
                    <li>
                      <IoIosCheckmark size={30} />
                      E-commerce platform integration
                    </li>
                    <li>
                      <IoIosCheckmark size={30} />
                      Delivery Partner Integration
                    </li>
                    <li>
                      <IoIosCheckmark size={30} />
                      Webhooks
                    </li>
                    <li>
                      <IoIosCheckmark size={30} />
                      Payment Automation
                    </li>
                    <li>
                      <IoIosCheckmark size={30} />
                      Interactive Forms (WhatsApp)
                    </li>
                  </ul>
                </div>
              </div>
              <div className="priceCard-content-card card3">
                <div className="priceCard-content-card-header">
                  <h2>Advantage</h2>
                  <p>
                    <FaRegUser /> 5 Users
                  </p>
                  <p>
                    <BiConversation /> 5000 Conversation
                  </p>
                </div>
                <div className="priceCard-content-card-button">
                  <Button
                    className="button"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    Choose Plan
                  </Button>
                </div>
                {/* <div className="priceCard-content-card-price">
                  <h1>
                    $299<span>/mo</span>
                  </h1>
                </div> */}
                <h3>Features:</h3>
                <div className="priceCard-content-card-features">
                  <ul>
                    <li>
                      <IoIosCheckmark size={30} />
                      Advanced Forms (WhatsApp)
                    </li>
                    <li>
                      <IoIosCheckmark size={30} />
                      Industry specific Fine Tuned LLM​
                    </li>
                    <li>
                      <IoIosCheckmark size={30} />
                      Task Module
                    </li>
                    <li>
                      <IoIosCheckmark size={30} />
                      Dedicated Hosting
                    </li>
                    <li>
                      <IoIosCheckmark size={30} />
                      Custom Domain
                    </li>
                    <li>
                      <IoIosCheckmark size={30} />
                      Priority Support
                    </li>
                    <li>
                      <IoIosCheckmark size={30} />
                      Dedicated Success Manager
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default PriceCard;
