import React from "react";
import Card from "antd/es/card/Card";

const FeaturesCardItem = (props) => {
  return (
    <>
      <Card
        style={{ width: "200px", height: "fit-content" }}
        className={props.content.className}
      >
        <div className="features-details-card-content">
          <div className="features-card-icon">
            <img src={props.content.imageURL} alt="feature logo" width={25} />
          </div>
          <div className="features-card-title">{props.content.title}</div>
          <div className="features-card-description">
            {props.content.description}
          </div>
        </div>
      </Card>
    </>
  );
};

export default FeaturesCardItem;
