import React, { useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

const AccordionSection = (props) => {
  const [openAccordions, setOpenAccordions] = useState([]);

  const handleToggleAccordion = (index) => {
    const newOpenAccordions = [...openAccordions];
    if (newOpenAccordions.includes(index)) {
      newOpenAccordions.splice(newOpenAccordions.indexOf(index), 1);
    } else {
      newOpenAccordions.push(index);
    }
    setOpenAccordions(newOpenAccordions);
  };

  return (
    <>
      <div className="outerContainer accordion-outer-container">
        <div className="innerContainer accordion-inner-container">
          <div className="accordion-container">
            <div className="accordion-section-left">
              <h2 className="sectionTitle left">Frequently Asked Questions</h2>
              <p className="para">
                Still have unanswered questions? Feel free to reach out to us at
                <span>info@inextlabs.com</span>
              </p>
            </div>

            <div className="accordion-section-right">
              {props.content.map((faq, index) => (
                <Accordion
                  key={index}
                  open={openAccordions.includes(index)}
                  className="mb-2 rounded-lg border px-4 accordion"
                >
                  <AccordionHeader
                    onClick={() => handleToggleAccordion(index)}
                    className={`border-b-0 transition-colors paragraphTitle accordion-header ${
                      openAccordions.includes(index)
                        ? "accordion-open"
                        : "accordion-close"
                    }`}
                  >
                    {faq.question}
                  </AccordionHeader>
                  <AccordionBody className="py-2">
                    <div
                      className="accordion-body-text para"
                      dangerouslySetInnerHTML={{
                        __html: faq.answer,
                      }}
                    ></div>
                  </AccordionBody>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccordionSection;
