import React from "react";
import ArticlesItem from "./ArticlesItem";
import Divider from "../Divider";
import { Link } from "react-router-dom";
import { newsContent } from "../company/news/newsContent";
import { Button } from "@material-tailwind/react";
const Articles = (props) => {
  const ArticlesContent = newsContent.newsItems.slice(0, 3);
  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <div className="articles-inner-container">
            <div className="articles-title-container">
              <div className="articles-section-title">Articles</div>
              <h2 className="articles-title">Latest News</h2>
            </div>
            <Divider />
            <div className="articles-list-outer-container">
              <div className="articles-list-container">
                {ArticlesContent.map((articles, index) => (
                  <ArticlesItem key={index} content={articles} />
                ))}
              </div>

              <div className="articles-list-more-button">
              
                  <Link to="/news">
                    <Button className="button">More News </Button>
                  </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Articles;
