import React from "react";
import { libraryContent } from "./libraryContent";
import { Link } from "react-router-dom";
import Divider from "../../Divider";

const LibraryItems = ({ content }) => {
  const linkTarget =
    content.type === "Guide"
      ? content.url
        ? `/resources/library/${content.url}`
        : `/`
      : content.type === "Video"
      ? content.videoURL
      : content.pdfURL;

  return (
    <div className={`${content.type.toLowerCase()}-container`}>
      <div className="library-main-container">
        <div className="library-container">
          <Link
            to={linkTarget}
            target="_blank"
            rel="noopener noreferrer"
            className="library-link"
          >
            <img
              src={content.imageURL}
              alt=""
              width="100%"
              className="library-hero-image"
            />
            <div className="library-type">
              <p className="para">{content.type}</p>
            </div>
            <h3 className="library-title paragraphTitle">{content.title}</h3>
          </Link>
        </div>
      </div>
    </div>
  );
};

const LibraryDetails = () => {
  const sortedLibrary = libraryContent.libraryItems.sort(
    (a, b) => b.publishedDate - a.publishedDate
  );
  const libraryTypes = ["Brochure", "Leaflet", "Video"];

  return (
    <div className="outerContainer">
      <div className="innerContainer">
        <div className="library-details-header">
          <h2 className="library-details-header-title sectionTitle">
            Resources Library
          </h2>
          <Divider />
          {libraryTypes.map((type) => {
            const items = sortedLibrary.filter((item) => item.type === type);
            return (
              <React.Fragment key={type}>
                <div className="library-section-title border-b pb-2">
                  <h1 className="paragraphTitle text-xl">{type}s</h1>
                </div>
                <div className="library-details-items">
                  {items.map((item, index) => (
                    <LibraryItems key={index} content={item} />
                  ))}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LibraryDetails;
