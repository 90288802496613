import { FaBrain, FaWhatsapp } from "react-icons/fa6";
import { LiaConnectdevelop } from "react-icons/lia";
import {
  MdBusinessCenter,
  MdLocalOffer,
  MdChat,
  MdEventSeat,
  MdThumbUp,
  MdLocalShipping,
  MdLibraryBooks,
} from "react-icons/md";
export const restaurentContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "Unlock new opportunities with our AI-powered solutions. Increase ROI with personalized recommendations, excellent customer service, and a boost in online orders and sales. Stay ahead by engaging your customers on their preferred platforms.",
    id: "1",
    imageURL: "/images/solutions/fnb/banner.png",
    solutionType: "byIndustry",
    background: "/images/solutions/healthcare/banner.jpg",
    title: "Maximize F&B Profits with AI Solutions",
  },
  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "/images/solutions/fnb/rapid-response.png",
      pointer: "/images/icons/pointer.svg",
      title: "Streamline Customer Service with Quick Responses",
      descriptionOne:
        "<strong>Fast and Efficient Replies:</strong> Resolve customer issues swiftly.",
      descriptionTwo:
        "<strong>Dedicated to Satisfaction:</strong> Show commitment with timely solutions.",
      descriptionThree:
        "<strong>Build Trust and Reputation:</strong> Enhance loyalty through reliable service.",
    },
    {
      id: "2",
      model: "model1",
      imageURL: "/images/solutions/fnb/order-taking.png",
      pointer: "/images/icons/pointer.svg",
      title: "Expand Your Reach with WhatsApp Broadcasts",
      descriptionOne:
        "<strong>Targeted Promotions:</strong> Boost engagement with personalized offers.",
      descriptionTwo:
        "<strong>Menu & Offer Updates:</strong> Keep customers informed about new dishes and offers.",
      descriptionThree:
        "<strong>Efficient Messaging:</strong> Broadcast to all customers at once.",
    },
    {
      id: "3",
      model: "model1",
      imageURL: "/images/solutions/fnb/payment-processing.png",
      pointer: "/images/icons/pointer.svg",
      title: "Showcase Your Menu for Easy Ordering",
      descriptionOne:
        "<strong>Easy Ordering:</strong> Place orders with just a few clicks.",
      descriptionTwo:
        "<strong>Engaging Digital Menu:</strong> Browse an appealing selection of dishes.",
      descriptionThree:
        "<strong>Personalized Experience:</strong> Enhance satisfaction with tailored menu options.",
    },
    {
      id: "4",
      model: "model1",
      imageURL: "/images/solutions/fnb/analytics.png",
      pointer: "/images/icons/pointer.svg",
      title: "Optimize Performance with Advanced Analytics",
      descriptionOne:
        "<strong>Track engagement and sales trends:</strong> Spot growth opportunities.",
      descriptionTwo:
        "<strong>Measure promotion effectiveness:</strong> Refine marketing strategies.",
      descriptionThree:
        "<strong>Understand customer preferences:</strong> Tailor offerings to drive satisfaction.",
    },
  ],

  integrations: {
    title: "We operate alongside your workflow",
    description:
      "Centralize all your tools and data effortlessly using simple integrations that connect your helpdesk software, CRM, order management, payment systems, and more.",
    content: [
      {
        id: "1",
        title: "Lalamove",
        imageURL: "/images/integration-icons/lalamove.png",
      },
      {
        id: "2",
        title: "WhatsApp",
        imageURL: "/images/integration-icons/whatsapp.svg",
      },
      {
        id: "3",
        title: "Square",
        imageURL: "/images/integration-icons/square.png",
      },
      {
        id: "4",
        title: "Stripe",
        imageURL: "/images/integration-icons/stripe.png",
      },
      {
        id: "5",
        title: "Dunzo",
        imageURL: "/images/integration-icons/dunzo.png",
      },
      {
        id: "6",
        title: "Shopify",
        imageURL: "/images/integration-icons/shopify.png",
      },
      {
        id: "7",
        title: "Magento",
        imageURL: "/images/integration-icons/magento.png",
      },
      {
        id: "8",
        title: "WooCommerce",
        imageURL: "/images/integration-icons/woocommerce.png",
      },
      {
        id: "9",
        title: "Mailchimp",
        imageURL: "/images/integration-icons/mailchimp.png",
      },
    ],
  },
  whyChooseUs: {
    title: "Why Choose Us",
    tagline: "It's More Than Just These Reasons",
    content: [
      {
        id: "1",
        title: "Pre-Built Templates",
        tagline:
          "Ready-made workflows for efficient end-to-end customer journeys in retail.",
        icon: <MdLibraryBooks size={20} />, // Icon representing templates
      },
      {
        id: "2",
        title: "WhatsApp Commerce",
        tagline:
          "Showcase your menu, automate advance table bookings, orders, and enhance the user journey.",
        icon: <FaWhatsapp size={20} />, // Icon representing WhatsApp
      },
      {
        id: "3",
        title: "Multi-Channel Integration",
        tagline:
          "Access 40+ platforms in 135+ languages via text, voice, and video.",
        icon: <LiaConnectdevelop size={20} />, // Icon representing multi-channel integration
      },
      {
        id: "4",
        title: "AI Assistance",
        tagline:
          "Boost efficiency, automate tasks, and cut costs with AI-powered conversations.",
        icon: <FaBrain size={20} />, // Icon representing AI assistance
      },
    ],
  },
  ValueProposition: {
    title: "Unlock Business Growth with Our Key Features",
    tagline:
      "AI in the F&B industry automates order taking, provides personalized recommendations, and handles customer inquiries, ultimately boosting efficiency and customer satisfaction",

    content: [
      {
        id: "1",
        title: "Seamless Ordering",
        tagline:
          "Enable direct ordering via WhatsApp, manage take-away slots, and integrate home-delivery to streamline customer service and convenience.",
        icon: <MdLocalShipping size={30} />,
      },
      {
        id: "2",
        title: "Instant Feedback",
        tagline:
          "Collect real-time feedback to understand customer preferences, pinpoint improvement areas, and enhance satisfaction.",
        icon: <MdThumbUp size={30} />,
      },
      {
        id: "3",
        title: "Smart Reservations",
        tagline:
          "Offer 24/7 table booking capabilities, minimizing no-shows and maximizing operational efficiency.",
        icon: <MdEventSeat size={30} />,
      },
      {
        id: "4",
        title: "Automated Responses",
        tagline:
          "Provide quick answers to common queries about menus, timings, and directions, reducing staff workload and improving service quality.",
        icon: <MdChat size={30} />,
      },
      {
        id: "5",
        title: "Targeted Promotions",
        tagline:
          "Utilize personalized offers and promotions to engage customers, driving sales and fostering loyalty.",
        icon: <MdLocalOffer size={30} />,
      },
      {
        id: "6",
        title: "Efficient Operations",
        tagline:
          "Optimize restaurant management with integrated systems for efficient service, reduced wait times, and better customer experiences.",
        icon: <MdBusinessCenter size={30} />,
      },
    ],
  },
  FAQ: [
    {
      id: "1",
      question: "1. How is AI Transforming the Food Industry?",
      answer:
        "AI boosts efficiency and reduces costs in the food industry. It streamlines production, predicts equipment maintenance, optimizes delivery routes, and minimizes waste. By automating tasks, AI helps lower downtime and ensures better resource management.",
    },
    {
      id: "2",
      question:
        "2. What is the necessity of AI integrated WhatsApp in F&B business?",
      answer:
        "WhatsApp integration allows F&B businesses to connect with customers on a platform they regularly use. This enables real-time communication for orders, support, and personalized service, enhancing customer experience and driving growth.",
    },
    {
      id: "3",
      question:
        "3. How does implementing AI in my F&B business improve customer efficiency?",
      answer:
        "Implementing AI in your F&B business can significantly enhance customer service by providing personalized recommendations, automating order and payment processes, and offering 24/7 support. AI technologies can analyse customer preferences and behaviour to tailor the dining experience, improve operational efficiency, and reduce wait times, leading to higher customer satisfaction and loyalty.",
    },
    {
      id: "4",
      question:
        "4. Are QR scanners safe and reliable for a safe dining experience?",
      answer:
        "As part of the iNextLabs restaurant API, we offer the option to send your customers an ordering link or a QR code for contactless dining. Customers can browse the menu and place their orders conveniently. We understand that some people may feel anxious about scanning QR codes due to potential data breaches. To address this concern, we ensure secure data encryption and are AI Verify Foundation certified, making it impossible for anyone to hack your details while you order your meal.",
    },
  ],

  stats: {
    title: "Elevate Customer Satisfaction with AI",
    description:
      "AI assistants transform the F&B industry by automating orders, personalizing recommendations, and handling inquiries, boosting efficiency and satisfaction.",
    content: [
      {
        id: "1",
        title: "Increase in Sales Conversions",
        count: 25,
      },
      {
        id: "2",
        title: "Boost in Customer Loyalty",
        count: 40,
      },
      {
        id: "3",
        title: "Growth in Online Reservations",
        count: 35,
      },
      {
        id: "4",
        title: "Rise in Average Order Value",
        count: 20,
      },
    ],
  },
  meta: {
    title: "AI Solutions for Restaurants and F&B - iNextLabs",
    description:
      "Leverage AI to personalize dining experiences, enhance customer engagement, boost sales, and streamline operations, driving innovation and improving efficiency with a comprehensive end-to-end solution.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
