import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Divider from "../Divider";

const Clients = (props) => {
  const images = props.content;

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const settings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="clients-container outerContainer">
      <h2 className="clients-container-title">
        Trusted By Companies Worldwide
      </h2>
<Divider/>
      <Slider
        className="logos-slider"
        {...settings}
        data-aos="flip-up"
        data-aos-duration="2000"
      >
        {images.map((imageUrl, index) => (
          <div className="slide" key={index}>
            <img src={imageUrl} alt="clints logo" className="logo-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Clients;
