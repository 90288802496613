import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import FeaturesCardItem from "./FeaturesCardItem";
const pointerIcon = "/images/icons/check-mark.svg";

const Features = (props) => {
  const FeaturesContent = props.content;
  useEffect(() => {
    AOS.init({
      duration: 500,
      once: false,
    });
  }, []);

  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <div className="features-container">
            <div
              className="features-details-container"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              {FeaturesContent.map((card, index) => (
                <FeaturesCardItem key={index} content={card} />
              ))}
            </div>

            <div
              className="features-content-container"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div className="features-content-section-title">Features</div>
              <h2 className="features-content-title">Our Core Features</h2>
              <div className="features-content-description">
                We provide a comprehensive suite of core features that enable
                that enable us to deliver exceptional chatbot solutions.
              </div>
              <div className="features-content-items">
                <div>
                  <img src={pointerIcon} alt="Feature pointer" width={15} /> Natural Language
                  Processing
                </div>
                <div>
                  <img src={pointerIcon} alt="Feature pointer" width={15} /> Machine Learning
                  (ML)
                </div>
                <div>
                  <img src={pointerIcon} alt="Feature pointer" width={15} /> Multi-channel
                  Support
                </div>
                <div>
                  <img src={pointerIcon} alt="Feature pointer" width={15} /> Contextual
                  Understanding
                </div>
                <div>
                  <img src={pointerIcon} alt="Feature pointer" width={15} /> Personalization
                </div>
                <div>
                  <img src={pointerIcon} alt="Feature pointer" width={15} /> Analytics and
                  Insights
                </div>
                <div>
                  <img src={pointerIcon} alt="Feature pointer" width={15} /> Integration
                  Capabilities
                </div>
                <div>
                  <img src={pointerIcon} alt="Feature pointer" width={15} /> Security and
                  Compliance
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
