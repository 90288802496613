import { Link } from "react-router-dom";
import Divider from "../Divider";
import SignUpModal from "../modal/SignUpModal";
import { useState } from "react";
import { Button } from "@material-tailwind/react";

const DiscoveryWorkshop = (props) => {
  const [isOpen, setIsOpen] = useState(false); // Define isOpen and setIsOpen

  return (
    <>
      <div className="outerContainer discovery-workshop-outer-container">
        <div className="innerContainer discovery-workshop-inner-container">
          <div className="discovery-workshop-container">
            <div className="discovery-workshop-content-wrapper">
              <div className="discovery-workshop-header">
                <h2 className="sectionTitle">
                  Start Your AI Journey with Our Discovery Workshop
                </h2>
                <p className="para">
                  Cut through the clutter of AI buzzwords and uncover real,
                  actionable solutions tailored to your business. Our Discovery
                  Workshop is your starting point to:
                </p>
                <Divider />
              </div>

              <div className="discovery-workshop-list">
                <ul>
                  <li>
                    <img
                      src="/images/icons/data-analysis.svg"
                      alt="pointer"
                      style={{ marginRight: "10px" }}
                    />
                    <span>Identify impactful AI use cases</span>
                  </li>
                  <li>
                    <img
                      src="/images/icons/accessibility.svg"
                      alt="pointer"
                      style={{ marginRight: "10px" }}
                    />
                    <span>Assess feasibility with expert analysis</span>
                  </li>
                  <li>
                    <img
                      src="/images/icons/visualization-of-data.svg"
                      alt="pointer"
                      style={{ marginRight: "10px" }}
                    />
                    <span>Visualize solutions and understand their ROI</span>
                  </li>
                  <li>
                    <img
                      src="/images/icons/track.svg"
                      alt="pointer"
                      style={{ marginRight: "10px" }}
                    />
                    <span>Chart a detailed roadmap for AI implementation</span>
                  </li>
                </ul>
              </div>
              <div className="discovery-workshop-button-section">
                <div className="discovery-workshop-button-container">
                  <Button
                    className="discovery-workshop-button button"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    Schedule Your Workshop
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </>
  );
};

export default DiscoveryWorkshop;
