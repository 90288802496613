import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import SignUpModal from "../modal/SignUpModal";
import { Button } from "@material-tailwind/react";
import { freeTrailClickFunction } from "../../app-insights/freeTrailClickFunction";

const HomeBanner = (props) => {
  const [isOpen, setIsOpen] = useState(false); // Define isOpen and setIsOpen
  const bannerContent = props.content;
  useEffect(() => {
    AOS.init({
      duration: 500,
      once: false,
    });
  }, []);

  return (
    <>
      {bannerContent[0].model === "homebanner" ? (
        <div
          className="home-banner-outer-container"
          style={{
            backgroundImage: `url("/images/banner-images/element-83.jpg")`,
          }}
        >
          <div className="home-banner-inner-container">
            <div className="home-banner-content-outer-container">
              <div className="home-banner-content-inner-container">
                <h1
                  className="home-banner-content-container-title"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  Democratizing AI for Businesses
                </h1>
                <div
                  className="home-banner-content-container-description"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  {bannerContent[0].description}
                </div>
                <div
                  className="home-banner-content-container-button"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  {/* <div className="primary-button">
                    <HashLink smooth to={bannerContent[0].primaryButton.url}>
                      <button className="button bannerPrimaryButton ">
                        {bannerContent[0].primaryButton.text}
                      </button>
                    </HashLink>
                  </div> */}
                  <div className="secondary-button  flex flex-col items-center">
                    {/* <HashLink smooth to="/sign-up"> */}
                    <Button
                      className="button bannerPrimaryButton"
                      onClick={() => {
                        setIsOpen(true);
                        freeTrailClickFunction();
                      }}
                    >
                      Start Your Free Trial
                    </Button>
                    <p className="credit-card-text">*No credit card required</p>

                    {/* </HashLink> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="home-banner-image-container">
              <img
                src={bannerContent[0].imageURL}
                alt=""
                data-aos="fade-down-left"
                data-aos-duration="2000"
              />
            </div> */}
          </div>
        </div>
      ) : (
        <>
          <div className="innerpage-banner-outer-container">
            <div className="innerpage-banner-inner-container">
              <div className="innerpage-banner-content-outer-container">
                <div className="innerpage-banner-content-inner-container">
                  <div className="innerpage-banner-content-container-main-title">
                    {bannerContent[0].mainTitle}
                  </div>
                  <h1 className="innerpage-banner-content-container-title">
                    {bannerContent[0].title}
                  </h1>
                  <div className="innerpage-banner-content-container-description">
                    {bannerContent[0].description}
                  </div>
                  <div className="innerpage-banner-content-container-button">
                    {/* <div className="primary-button">
                      <HashLink smooth to={bannerContent[0].primaryButton.url}>
                        <button className="button bannerPrimaryButton ">
                          {bannerContent[0].primaryButton.text}
                        </button>
                      </HashLink>
                    </div> */}
                    <div className="secondary-button">
                      <button
                        className="button bannerPrimaryButton "
                        onClick={() => {
                          setIsOpen(true);
                        }}
                      >
                        7 Days Free Trial
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="innerpage-banner-image-container">
                <img src={bannerContent[0].imageURL} alt="banner image" />
              </div>
            </div>
          </div>
        </>
      )}
      <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default HomeBanner;
