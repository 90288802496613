import { RiSurveyLine } from "react-icons/ri";
import {
  MdInsights,
  MdPendingActions,
  MdProductionQuantityLimits,
} from "react-icons/md";
import { PiStrategyBold, PiTargetBold } from "react-icons/pi";
import { AiOutlineLike } from "react-icons/ai";

export const salesAndMarketingContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "Enhance your sales and marketing processes with Generative AI-powered virtual assistants, allowing your team to focus on high-value activities while automating routine tasks. Streamline operations and boost efficiency with intelligent support that manages the complexities, ensuring every customer interaction is optimized for success.",
    id: "1",
    image: {
      img1: "/images/solutions/sales-marketing/2147695966.jpg",
      img2: "/images/solutions/sales-marketing/3215.jpg",
      img3: "/images/solutions/sales-marketing/2150063134.jpg",
      img4: "/images/solutions/sales-marketing/129003.jpg",
      img5: "/images/solutions/sales-marketing/7882.jpg",
    },
    background: "/images/solutions/healthcare/banner.jpg",
    sectionTile: "",
    title: "Amplify Sales and Marketing Performance with AI",
  },
  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "/images/solutions/sales-marketing/91624.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Streamline Sales Engagement",
      description:
        "Automate the sales process to prioritize valuable leads and tasks, ensuring sales teams invest time where it matters most. Enhance productivity and drive better results with automated lead management and real-time notifications.",
      descriptionOne: "Automated lead scoring and assignment",
      descriptionTwo: "Trigger-based email marketing campaigns",
      descriptionThree: "Real-time sales alerts and notifications",
    },

    {
      id: "2",
      model: "model1",
      imageURL:
        "/images/solutions/sales-marketing/Optimize-Marketing-Campaigns.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Optimize Marketing Campaigns",
      description:
        "Leverage analytics to tailor marketing strategies, ensuring content and messaging hit the mark. Through A/B testing and multi-channel orchestration, refine campaigns for maximum impact and engagement.",
      descriptionOne: "Dynamic content personalization",
      descriptionTwo: "A/B testing for campaigns",
      descriptionThree: "Multi-channel marketing orchestration",
    },
    {
      id: "3",
      model: "model1",
      imageURL: "/images/solutions/sales-marketing/businesspeople-meeting.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Data-Driven Sales & Marketing",
      description:
        "Utilize analytics to gain insights into sales trends and marketing effectiveness, driving strategic decisions. Track key performance indicators to understand and enhance the customer journey from initial contact to sale.",
      descriptionOne: "Sales forecasting and revenue tracking",
      descriptionTwo: "Marketing performance analytics",
      descriptionThree: "Customer segmentation and targeting",
    },
    {
      id: "4",
      model: "model1",
      imageURL: "/images/solutions/sales-marketing/customer-interaction.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Seamless Customer Interactions",
      description:
        "Provide a cohesive customer experience across all platforms, integrating CRM systems for a complete view of customer interactions. Use chatbots and social media to ensure consistent, timely, and effective communication.",
      descriptionOne: "CRM integration for a 360-degree customer view",
      descriptionTwo: "Chatbots for instant customer service",
      descriptionThree: "Social media integration for broader reach",
    },
  ],

  ValueProposition: {
    title: "AI Empowerment in Sales & Marketing",
    tagline: "Drive Growth, Enhance Efficiency, and Multiply Success with AI",

    content: [
      {
        id: "1",
        title: "Targeted Lead Qualification",
        tagline:
          "Focus on high-quality leads with our precise qualification algorithms",
        icon: <PiTargetBold size={30} />,
      },
      {
        id: "2",
        title: "Personalized Product Information",
        tagline:
          "Automatically present tailored product information to facilitate informed purchasing decisions",
        icon: <MdProductionQuantityLimits size={30} />,
      },
      {
        id: "3",
        title: "Smart Recommendation Engine",
        tagline:
          "Utilize AI to recommend products that align with customer preferences and behaviours",
        icon: <AiOutlineLike size={30} />,
      },
      {
        id: "4",
        title: "Effective Upsell and Cross-sell Strategies",
        tagline:
          "Leverage data to strategically offer additional products that complement the customer's initial purchase",
        icon: <PiStrategyBold size={30} />,
      },
    ],
  },

  integrations: {
    title: "Seamless Integration with Leading Platforms",
    description:
      "Effortlessly connect with your favorite tools and platforms to streamline your workflow and enhance productivity.",
    content: [
      {
        id: "1",
        title: "Salesforce",
        imageURL: "/images/integration-icons/salesforce.png",
      },
      {
        id: "2",
        title: "HubSpot",
        imageURL: "/images/integration-icons/hubspot.png",
      },
      {
        id: "3",
        title: "Mailchimp",
        imageURL: "/images/integration-icons/mailchimp.png",
      },
      {
        id: "4",
        title: "Constant Contact",
        imageURL: "/images/integration-icons/constant-contact.png",
      },
      {
        id: "5",
        title: "Hootsuite",
        imageURL: "/images/integration-icons/hootsuite.png",
      },
      {
        id: "6",
        title: "Buffer",
        imageURL: "/images/integration-icons/buffer.png",
      },
      {
        id: "7",
        title: "Google Analytics",
        imageURL: "/images/integration-icons/google-analytics.png",
      },
      {
        id: "8",
        title: "Tableau",
        imageURL: "/images/integration-icons/tableau.png",
      },
      {
        id: "9",
        title: "Leadfeeder",
        imageURL: "/images/integration-icons/lead-feeder.png",
      },
      {
        id: "10",
        title: "Unbounce",
        imageURL: "/images/integration-icons/unbounce.png",
      },
      {
        id: "11",
        title: "Google Ads",
        imageURL: "/images/integration-icons/google-ads.png",
      },
      {
        id: "12",
        title: "Facebook Ads Manager",
        imageURL: "/images/integration-icons/facebook-ads-manager.png",
      },
      {
        id: "13",
        title: "Slack",
        imageURL: "/images/integration-icons/slack.svg",
      },
      {
        id: "14",
        title: "Microsoft Teams",
        imageURL: "/images/integration-icons/teams.svg",
      },
      {
        id: "15",
        title: "Zoom",
        imageURL: "/images/integration-icons/zoom.png",
      },
      {
        id: "16",
        title: "GoToWebinar",
        imageURL: "/images/integration-icons/gotowebinar.png",
      },
      {
        id: "17",
        title: "WordPress",
        imageURL: "/images/integration-icons/wordpress.png",
      },
      {
        id: "18",
        title: "Joomla",
        imageURL: "/images/integration-icons/joomla.png",
      },
      {
        id: "19",
        title: "Marketo",
        imageURL: "/images/integration-icons/marketo.png",
      },
      {
        id: "20",
        title: "Pardot",
        imageURL: "/images/integration-icons/pardot.png",
      },
    ],
  },
  FAQ: [
    {
      id: "1",
      question: "1. How can AI revolutionize sales and marketing processes?",
      answer:
        "AI holds the potential to transform sales and marketing processes by automating repetitive tasks, providing personalized customer interactions, and offering valuable insights for precise sales forecasting and strategic decision-making.",
    },
    {
      id: "2",
      question:
        "2. What benefits does AI offer in sales and marketing operations?",
      answer:
        "Embracing AI in sales and marketing operations yields numerous advantages, including heightened productivity, enhanced customer engagement, superior sales performance, improved sales pipeline management, and more informed decision-making.",
    },
    {
      id: "3",
      question:
        "3. How does the Work Assistant from iNextLabs aid in sales and marketing automation?",
      answer:
        "iNextLabs' Work Assistant streamlines sales and marketing operations by automating CRM updates, orchestrating follow-ups, and executing tasks on behalf of sales and marketing teams across diverse systems and platforms. This facilitates smoother operations and enables teams to focus on high-value activities.",
    },
    {
      id: "4",
      question:
        "4. Can AI-driven assistants facilitate personalized sales and marketing interactions?",
      answer:
        "Certainly, AI-driven assistants excel in tailoring sales and marketing interactions to individual preferences. By analyzing customer data and understanding unique needs, these assistants craft engaging and personalized interactions, leading to more effective sales and marketing conversations.",
    },
  ],
  innerFeatures: [
    {
      id: "1",
      title: "Smart Language Model",
      imageURL: "/images/solutions/healthcare/smart.svg",
      point1: "Enhanced Efficiency",
      point2: "24/7 Availability",
      point3: "Scalability and Consistency",
      point4: "Personalized and Contextualized Interaction",
    },
    {
      id: "1",
      title: "Powered by ChatGPT",
      imageURL: "/images/solutions/healthcare/ai.svg",
      point1: "State-of-the-art Natural Language Processing",
      point2: "Deep Learning Architecture",
      point3: "Large-Scale Pretraining",
      point4:
        "Advanced and Contextual Language Understanding and Generation Capabilities ",
    },
    {
      id: "1",
      title: "Secure and compliant conversations ",
      imageURL: "/images/solutions/healthcare/complaint.svg",
      point1: "Data Privacy",
      point2: "Regulatory Compliance",
      point3: "Secure Infrastructure",
      point4: "Encrypted Communication",
    },
  ],
  stats: {
    title: "Industry Insights on AI in Sales and Marketing",
    description:
      "Generative AI automation in sales and marketing enhances operational efficiency and personalizes customer engagement. This innovation streamlines processes, reduces costs, and supports business growth and scalability.",
    content: [
      {
        id: "1",
        title:
          "Increase in sales productivity for firms using AI-driven sales tools",
        count: 15,
        source: "Forrester Research",
      },
      {
        id: "2",
        title:
          "AI integration in B2B sales interactions by 2026, reducing human involvement significantly",
        count: 85,
        source: "Gartner",
      },
      {
        id: "3",
        title:
          "likelihood for top sales teams to adopt AI in sales processes versus lower-performing peers",
        count: 4,
        countType: "x",
        source: "Salesforce",
      },
      {
        id: "4",
        title:
          "potential revenue growth for businesses employing AI in their marketing strategie",
        count: 30,
        source: "McKinsey & Company",
      },
    ],
  },
  meta: {
    title: "AI Solutions for Sales and Marketing - iNextLabs",
    description:
      "AI-driven solutions for sales and marketing at Inext Labs. Transform your strategies with innovative technologies tailored for business growth.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
