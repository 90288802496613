import React from "react";

import BuisnessStatement from "../statement/BuisnessStatement";
const PromoVideo = (props) => {
  return (
    <>
      <div className="promo-video-container">
        <div className="innerContainer">
          <video autoPlay muted controls loop className="promo-video">
            <source src={props.videoURL} />
          </video>
        </div>
      </div>
      {/* <BuisnessStatement /> */}
    </>
  );
};

export default PromoVideo;
