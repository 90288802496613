import React from "react";

function TestimonialItem(props) {
  return (
    <>
      <div className="testimonialItem-outer-container">
        <div className="testimonialItem-inner-container">
          <div className="testimonialItem-container">
            <div className="testimonialItem-image-container">
              <img src={props.content.imageURL} alt="testimonial logo" />
            </div>
            <div className="testimonialItem-content-container">
              {props.content.description}
            </div>
            <div className="testimonialItem-about-container">
              <p>
                {props.content.name},{" "}
                <span>
                  {props.content.position}, {props.content.company}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TestimonialItem;
