import { RiSurveyLine } from "react-icons/ri";
import {
  MdInsights,
  MdOutlineDashboardCustomize,
  MdOutlineSecurity,
  MdPendingActions,
} from "react-icons/md";
import { VscFeedback } from "react-icons/vsc";

export const humanresourcesContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "Introducing our Generative AI-powered HR chatbot, a smart solution designed to transform your employee experience. This intelligent bot specializes in instant query resolution, streamlining processes, and significantly improving employee satisfaction.",
    id: "1",
    image: {
      img1: "/images/solutions/hr/196.jpg",
      img2: "/images/solutions/hr/pexels-polina-zimmerman-3746957.jpg",
      img3: "/images/solutions/hr/88526.jpg",
      img4: "/images/solutions/hr/3430.jpg",
      img5: "/images/solutions/hr/2411.jpg",
    },
    background: "/images/solutions/healthcare/banner.jpg",
    sectionTile: "",
    title: "Elevate Your Employee Engagement with AI ",
  },
  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "/images/solutions/hr/daily-task.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Enable Instant Self-Serve to Address Everyday Tasks",
      description:
        "Tackle regular employee inquiries from technical issues to policy questions with a HR assistant that seamless integrates within your company and be available to your employees on channels like Teams, Slack, WhatsApp, Web, etc. By empowering employees to resolve their queries, you can significantly cut operational costs.",
      descriptionOne: "Extensive knowledge base",
      descriptionTwo: "Answer questions in any language",
      descriptionThree: "Integrate with Active Directory",
    },

    {
      id: "2",
      model: "model1",
      imageURL: "/images/solutions/hr/quick-reply.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Achieve Faster Query Resolution with Smart Assistance",
      description:
        "Boost your live agents' productivity with advanced tools like auto assist, which provide rapid recommendations and conversation summaries for quicker and more effective resolutions. This frees up HR and ITSM staff to concentrate on initiatives that add significant value to employee experiences.",
      descriptionOne: "Intelligent auto assist suggestions",
      descriptionTwo: "Quick access to conversation summaries",
      descriptionThree: "Pre-set quick replies for common queries",
    },
    {
      id: "3",
      model: "model1",
      imageURL: "/images/solutions/hr/knowledge-base-quick-access.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Utilize a Comprehensive Knowledgebase for Quick Access",
      description:
        "With iNextLabs Chatbot, establish a dynamic knowledge base that ingests and organizes large data volumes with ease, allowing for the automation of frequent queries and ensuring employees have seamless access to the information they need.",
      descriptionOne: "Large-scale data management",
      descriptionTwo: "Quick automation of routine queries",
      descriptionThree: "Dynamic and up-to-date information repository",
    },
    {
      id: "4",
      model: "model1",
      imageURL: "/images/solutions/hr/training-people.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Enhance Learning and Well-being for Greater Engagement",
      description:
        "Leverage automated feedback mechanisms to understand employee sentiment and offer personalized training programs tailored to their skill needs. This fosters a culture of continuous learning and problem-solving, thereby improving performance and engagement levels.",
      descriptionOne: "Automated collection of employee feedback",
      descriptionTwo: "Tailored skill development programs",
      descriptionThree: "Support for continuous learning and problem-solving",
    },
  ],
  ValueProposition: {
    title: "Smart AI for Elevated HR Excellence",
    tagline:
      " Boost Engagement, Streamline Operations, and Elevate Satisfaction with AI",

    content: [
      {
        id: "1",
        title: "Conversational Surveys",
        tagline:
          "Make surveys interactive to boost engagement and response rates, leveraging quizzes and polls for lively feedback collection. ",
        icon: <RiSurveyLine size={30} />,
      },
      {
        id: "2",
        title: "Actionable Insights",
        tagline:
          "Transform feedback into strategic actions to enhance workplace satisfaction and drive business performance. ",
        icon: <MdInsights size={30} />,
      },
      {
        id: "3",
        title: "Employee Feedback Analysis",
        tagline:
          "Analyse employee feedback for insights into their satisfaction and areas for improvement, guiding targeted interventions.",
        icon: <VscFeedback size={30} />,
      },
      {
        id: "4",
        title: "AI Action Planning",
        tagline:
          "Employ AI to analyse feedback and generate actionable plans, aiding swift and informed HR decision-making.",
        icon: <MdPendingActions size={30} />,
      },
      {
        id: "5",
        title: "Customization & Scalability",
        tagline:
          "Adapt the chatbot to your organization’s specific needs, ensuring it delivers relevant and effective support.",
        icon: <MdOutlineDashboardCustomize size={30} />,
      },
      {
        id: "6",
        title: "Data Security & Compliance",
        tagline:
          "Maintain strict data security and adhere to compliance standards to protect employee information and build trust. ",
        icon: <MdOutlineSecurity size={30} />,
      },
    ],
  },

  integrations: {
    title: "We operate alongside your workflow",
    description:
      "Centralize all your tools and data effortlessly using simple integrations that connect your helpdesk software, CRM, order management, payment systems, and more.",
    content: [
      {
        id: "1",
        title: "SAP",
        imageURL: "/images/integration-icons/sap.svg",
      },
      {
        id: "2",
        title: "Workplace",
        imageURL: "/images/integration-icons/workplace.svg",
      },

      {
        id: "3",
        title: "WhatsApp",
        imageURL: "/images/integration-icons/whatsapp.svg",
      },
      {
        id: "4",
        title: "Teams",
        imageURL: "/images/integration-icons/teams.svg",
      },
      {
        id: "5",
        title: "Microsoft",
        imageURL: "/images/integration-icons/microsoft.svg",
      },
      {
        id: "6",
        title: "Oracle",
        imageURL: "/images/integration-icons/oracle.svg",
      },
      {
        id: "7",
        title: "Slack",
        imageURL: "/images/integration-icons/slack.svg",
      },
      {
        id: "8",
        title: "ZOHO",
        imageURL: "/images/integration-icons/zoho.svg",
      },
    ],
  },
  FAQ: [
    {
      id: "1",
      question: "1. What functions does an HR chatbot serve?",
      answer:
        "An HR chatbot is an artificial intelligence-powered tool designed to automate and streamline various human resources tasks. It interacts with employees through conversational interfaces, providing immediate assistance with HR-related queries, automating routine processes, and enhancing employee engagement and support.",
    },
    {
      id: "2",
      question: "2. In what ways can HR departments leverage chatbots?",
      answer:
        "Chatbots in HR are used to manage a wide range of tasks, including answering employee FAQs, guiding through company policies, automating leave requests, managing onboarding processes, and facilitating training and development activities. They serve as a 24/7 support system, improving the efficiency and accessibility of HR services.",
    },

    {
      id: "3",
      question: "3. How can ChatGPT enhance human resources operations?",
      answer:
        "ChatGPT for HR refers to the application of the ChatGPT model, a form of generative AI, within the human resources domain. It's designed to understand and generate human-like text responses, aiding in tasks such as answering HR inquiries, generating reports, and even assisting in the recruitment and selection process by screening candidates or conducting initial interviews.",
    },
    {
      id: "4",
      question: "4. What role does AI play in transforming HR practices? ",
      answer:
        "AI helps HR by automating repetitive and time-consuming tasks, allowing HR professionals to focus on more strategic and impactful activities. AI can assist in talent acquisition, data analysis, employee onboarding, performance management, and personalized employee experiences. It enhances decision-making through predictive analytics and improves the overall efficiency and effectiveness of HR functions.",
    },
  ],

  innerFeatures: [
    {
      id: "1",
      title: "Smart Language Model",
      imageURL: "/images/solutions/healthcare/smart.svg",
      point1: "Enhanced Efficiency",
      point2: "24/7 Availability",
      point3: "Scalability and Consistency",
      point4: "Personalized and Contextualized Interaction",
    },
    {
      id: "1",
      title: "Powered by ChatGPT",
      imageURL: "/images/solutions/healthcare/ai.svg",
      point1: "State-of-the-art Natural Language Processing",
      point2: "Deep Learning Architecture",
      point3: "Large-Scale Pretraining",
      point4:
        "Advanced and Contextual Language Understanding and Generation Capabilities ",
    },
    {
      id: "1",
      title: "Secure and compliant conversations ",
      imageURL: "/images/solutions/healthcare/complaint.svg",
      point1: "Data Privacy",
      point2: "Regulatory Compliance",
      point3: "Secure Infrastructure",
      point4: "Encrypted Communication",
    },
  ],
  stats: {
    title:
      "Experience the impact now and elevate your HR efficiency for the future ",
    description:
      "HR chatbots streamline employee support, offering consistent responses and scalable assistance, while providing valuable data-driven insights for HR optimization.",
    content: [
      {
        id: "1",
        title: "faster response to employee inquiries",
        count: 95,
      },
      {
        id: "2",
        title: "less time for employees to find essential information",
        count: 80,
      },
      {
        id: "3",
        title: "more strategic time for HR professionals",
        count: 60,
      },
      {
        id: "4",
        title: "increase in overall employee satisfaction",
        count: 40,
      },
    ],
  },
  meta: {
    title: "AI Solutions for Human Resources - iNextLabs",
    description:
      "Explore AI-powered solutions for human resources at Inext Labs. Enhance recruitment, employee management, and HR operations with advanced technology.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
