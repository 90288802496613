export const partnersContent = {
  banner: {
    button: {
      text: "Become a Partner",
      url: "/partners/#partnerform",
    },
    description:
      "With our partners' expertise, we provide exceptional customer experiences.",
    id: "1",
    imageURL: "/images/banner-images/careers.png",
    sectionTile: "",
    title: "iNextLabs' Collaborative Partner Network",
    model: "partners",
    videoURL:
      "https://inextlabswebassets.blob.core.windows.net/video/pexels-mart-production-7534401 (1080p).mp4",
  },

  partners: [
    "/images/partners-logo/microsoft.png",
    "/images/partners-logo/facebook.png",
    "/images/partners-logo/google.png",
    "/images/partners-logo/Espore-logo.png",
    "/images/partners-logo/Kel_logo.png",
    "/images/partners-logo/logonewsmart.png",
    // "/images/partners-logo/mark-enterprise.png",
    "/images/partners-logo/medisys.png",
    // "/images/partners-logo/mm21_cropped.png",
    "/images/partners-logo/new_mint-logo.png",
    "/images/partners-logo/vlanasia-logo.png",
    // "/images/partners-logo/wcube_logo.png",
    "/images/partners-logo/nexsap.png",
    "/images/partners-logo/rakuten.png",
    "/images/partners-logo/onet-logo.png",
    "/images/partners-logo/zoho.png",
    "/images/partners-logo/zendesk.png",
  ],

  whyiNextLabsContent: [
    {
      title: "Expand Product Range with AI Solutions",
      description:
        "Introduce industry-leading AI solutions to your clients by integrating iNextLabs' advanced technology into your offerings. Help your clients engage effectively on popular messaging channels, boosting their lead generation and revenue",
    },
    {
      title: "New Revenue Streams",
      description:
        "Unlock new financial opportunities through referrals and reselling. Our partnership model is designed to create lucrative revenue streams, benefiting your business growth",
    },
    {
      title: "Exclusive Resources and Support",
      description:
        "Gain access to a wealth of exclusive partner resources, including comprehensive sales and marketing materials. Receive dedicated support and technical assistance to ensure smooth operations and successful client engagements.",
    },
    {
      title: "Collaborative Marketing Efforts",
      description:
        "Engage in joint marketing and promotional activities with iNextLabs. Collaborate with experts for sales activities and training, enabling you to leverage combined strengths for mutual success",
    },
    {
      title: "Speedy Market Entry and Innovation",
      description:
        "Rapidly go to market with joint strategies and a solid support system, aiming to surpass revenue and customer satisfaction goals. Drive innovation at scale, offering AI-powered conversational experiences to new customers",
    },
  ],

  types: [
    {
      id: "1",
      title: "Technology Partners",
      logo: "/images/partners-logo/icons/skill-development.svg",
      description:
        "Enhance your software or service by integrating with iNextLabs, crafting integrated solutions that deliver added value to customers.",
    },
    {
      id: "2",
      title: "Channel Partners",
      logo: "/images/partners-logo/icons/seller.svg",
      description:
        "Offer iNextLabs' platform through reselling and distribution, aiding clients in revolutionizing their customer interaction and communication approaches.",
    },
    {
      id: "3",
      title: "Referral Partners",
      logo: "/images/partners-logo/icons/affiliate.svg",
      description:
        "Suggest iNextLabs to your business network and receive commission rewards for every successful referral.",
    },
  ],

  testimonial: [
    {
      name: "Sajit Nair",
      company: "New Smart Office Automation, Dubai",
      logo: "/images/partners-logo/logonewsmart.png",
      description:
        "Thrilled about partnering with iNextLabs for cutting-edge Conversational-AI solutions, boosting customer engagement and satisfaction. Their expertise gives us a competitive edge, providing innovative, customer-centric offerings. iNextLabs has been instrumental in enhancing our market presence.",
    },
    {
      name: "Ashok",
      company: "Par3 Software, Singapore",
      logo: "/images/partners-logo/Par3.png",
      description:
        "iNextLabs' tech and committed team facilitated seamless integration of their solution into our healthcare systems. The result: improved patient communication, streamlined scheduling, and enhanced remote consultations. Our ongoing partnership promises continued innovation for an exceptional healthcare experience.",
    },
    {
      name: "Frank The",
      company: "OneT Solutions , Singapore",
      logo: "https://onet.sg/wp-content/uploads/2021/04/OneT-logo-color-horizontal-rgb-copy_OneT-logo-color-horizontal-cmyk.svg",
      description:
        "Partnering with iNextLabs from the start, their stable product suite fuels our marketing and sales strategies. With efficient solutions and exceptional management, our synergy propels us to greater success. Excited about future possibilities, we anticipate continued fruitful collaboration with iNextLabs.",
    },
  ],
  meta: {
    title: "Partnerships with iNextLabs | Collaborate with Us",
    description:
      "Explore partnership opportunities with Inext Labs. Collaborate on innovative AI solutions and drive mutual success. Contact us to discuss partnership possibilities.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "/images/banner-images/partners.png",
    type: "website",
  },
};
