import {
  MdInsights,
  MdAndroid,
  MdPeople,
  MdSecurity,
  MdLightbulbOutline,
  MdCloudUpload,
  MdCreate,
  MdEqualizer,
  MdFolderSpecial,
} from "react-icons/md";
export const inFlowEnterpriseContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "Take your business operations to the next level with inFlow Enterprise. Dive into a world where AI meets the big demands of your business. Efficiency and customer satisfaction aren’t just goals—they're what we deliver. Ready to go where your business lives, our LLM-Powered solutions work seamlessly within your own infrastructure or in the cloud. It’s time to boost your enterprise with smart AI and see your business thrive. ",
    id: "1",
    imageURL: "/images/products/inflow-enterprise/banner.jpg",
    background: "/images/solutions/healthcare/banner.jpg",
    sectionTile: "",
    title: "Pioneering AI Innovation for Enterprises",
  },
  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "/images/products/inflow-enterprise/virtual-agents.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Deploy LLM-based Virtual Agents",
      description:
        "Take your customer service further with our LLM-based Virtual Agents. Equipped with the latest AI technology, they provide exceptional, personalized service that scales to meet the needs of any enterprise. Ready to engage customers on any platform, these agents bring a level of service that's not just responsive but anticipatory, understanding customer needs to deliver tailor-made solutions.",
      descriptionOne: "Hyper-tailored customer service.",
      descriptionTwo: "Consistent quality across channels.",
      descriptionThree: "Smooth voice and digital integration.",
    },
    {
      id: "2",
      model: "model1",
      imageURL:
        "/images/products/inflow-enterprise/summarized-data-insights.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Extract Summarized Data Insights",
      description:
        "Our summarization tools cut through the clutter, transforming dense documents into crisp, digestible insights that power decision-making. Instantly grasp the heart of complex data and make informed decisions without wading through pages of information. This is data made simple, precise, and ready for executive action.",
      descriptionOne: "Key insights within seconds.",
      descriptionTwo: "Decision-making with confidence.",
      descriptionThree: "Synthesis of content at scale.",
    },
    {
      id: "3",
      model: "model1",
      imageURL:
        "/images/products/inflow-enterprise/unstructured-data-analysis.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Analyze Unstructured Data",
      description:
        "Unleash the potential of your raw data with our advanced analytics. Turn disparate data points into coherent, visual narratives that reveal the bigger picture and guide strategic planning. Empower your enterprise with the foresight to act on opportunities and navigate challenges with intelligence derived from deep within your data.",
      descriptionOne: "Transformative data visualization.",
      descriptionTwo: "Empowered decision-making framework.",
      descriptionThree: "Deep-dive into business analytics.",
    },
    {
      id: "4",
      model: "model1",
      imageURL:
        "/images/products/inflow-enterprise/classify-data-intelligently.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Classify Data Intelligently",
      description:
        "Master your data with our intelligent classification. Our AI algorithms deftly categorize information, making it simpler to access, understand, and act upon. It's about bringing order to data chaos, highlighting issues before they become problems, and identifying customer segments to drive targeted strategies.",
      descriptionOne: "Streamlined data management.",
      descriptionTwo: "Proactive issue identification.",
      descriptionThree: "Precision in market targeting.",
    },
  ],

  ValueProposition: {
    title: "inFlow Enterprise Advantages",
    tagline: "Empowering Your Enterprise with AI-Driven Excellence",

    content: [
      {
        id: "1",
        title: "Flexible Deployment Solutions",
        tagline: "Deployed to your on-premise or your cloud infrastructure.",
        icon: <MdCloudUpload size={30} />,
      },
      {
        id: "2",
        title: "Intelligence Services",
        tagline:
          "Tailored AI solutions for data handling, machine learning, and advanced analytics to boost performance.",
        icon: <MdLightbulbOutline size={30} />,
      },
      {
        id: "3",
        title: "inFlow Shield",
        tagline:
          "Protect your LLMs against hallucination, prompt injection, and other security metrics.",
        icon: <MdSecurity size={30} />,
      },
      {
        id: "4",
        title: "Live Agent",
        tagline:
          "Seamless handoff from AI to human support for complex issues.",
        icon: <MdPeople size={30} />,
      },
      {
        id: "5",
        title: "LLM-based Virtual Agents",
        tagline:
          "Transform customer service with AI that understands your business.",
        icon: <MdAndroid size={30} />,
      },
      {
        id: "6",
        title: "Summarized Data Insights",
        tagline:
          "Make informed decisions with key data points from extensive documentation.",
        icon: <MdInsights size={30} />,
      },
      {
        id: "7",
        title: "Custom Content Creation",
        tagline:
          "Engage and convert with uniquely personalized customer communications.",
        icon: <MdCreate size={30} />,
      },
      {
        id: "8",
        title: "Advanced Data Analysis",
        tagline:
          "Leverage deep insights for a competitive edge and smarter strategy.",
        icon: <MdEqualizer size={30} />,
      },
      {
        id: "9",
        title: "Data Classification",
        tagline:
          "Organize your data landscape for efficiency and targeted action.",
        icon: <MdFolderSpecial size={30} />,
      },
    ],
  },

  FAQ: [
    {
      id: "1",
      question:
        "1. What distinguishes inFlow Enterprise's virtual agents from others?",
      answer:
        "Our virtual agents stand out due to their utilization of cutting-edge LLM technology. Unlike traditional AI agents, which often struggle to grasp the subtleties of various industries, our agents are powered by advanced Language Model Models (LLMs), enabling them to comprehend and adapt to the nuanced language and requirements specific to your domain. This ensures that interactions with customers are not just efficient but also highly personalized, leading to enhanced customer satisfaction and loyalty.",
    },
    {
      id: "2",
      question:
        "2. How does inFlow Enterprise safeguard data confidentiality in summaries?",
      answer:
        "At inFlow Enterprise, we prioritize the security and confidentiality of your data above all else. When generating summaries from your documents, we employ stringent security measures and protocols to ensure that sensitive information remains protected at all times. Our state-of-the-art encryption methods and access controls guarantee that only authorized personnel have access to the summarized data, maintaining the confidentiality and integrity of your business-critical information.",
    },
    {
      id: "3",
      question: "3. Can inFlow Enterprise personalize interactions at scale?",
      answer:
        "Absolutely. With inFlow Enterprise, personalization is not limited by scale. Our platform harnesses the power of big data analysis to gain deep insights into customer preferences, behaviors, and engagement patterns. This wealth of data allows us to craft highly personalized interactions that resonate with each individual, regardless of the size or complexity of your customer base. By delivering tailored content and experiences at scale, inFlow Enterprise helps businesses foster stronger connections with their customers, driving engagement, satisfaction, and ultimately, business growth.",
    },
    {
      id: "4",
      question:
        "4. Does inFlow Enterprise seamlessly integrate with existing systems?",
      answer:
        "Absolutely. One of the key strengths of inFlow Enterprise is its seamless integration capabilities. Whether your existing infrastructure is on-premise or cloud-based, our solution is designed to effortlessly integrate with your systems and applications. Through robust APIs and flexible integration options, we ensure that inFlow Enterprise seamlessly fits into your workflow, minimizing disruption and maximizing efficiency. Whether you're using Microsoft Azure, Amazon Web Services, Google Cloud Platform, or any other relevant platform, rest assured that inFlow Enterprise will integrate seamlessly to meet your business needs.",
    },
  ],
  stats: {
    title: "Start Now & Witness Instant Impact with inFlow Enterprise",
    description: "Achieve significant business outcomes.",
    content: [
      {
        id: "1",
        title: "More Efficient Customer Service",
        count: 40,
      },
      {
        id: "2",
        title: "Faster Integration",
        count: 2,
        countType: "x",
      },
      {
        id: "3",
        title: "Cost Reduction",
        count: 30,
      },
      {
        id: "4",
        title: "Improved Accuracy",
        count: 70,
      },
    ],
  },
  meta: {
    title: "Inflow Enterprise Solutions | Pioneering AI Innovation for Enterprises - iNextLabs",
    description:
      "Elevate your operations with inFlow Enterprise, where AI-powered solutions boost efficiency and customer satisfaction, seamlessly integrating into your infrastructure or the cloud.",
    locale: "en_US",
    siteName: "inFlow Enterprise",
    image: "/images/products/inflow-enterprise/banner.jpg",
    type: "website",
  },
};
