import React from "react";
import { Card } from "antd";
import { RightOutlined, CalendarOutlined } from "@ant-design/icons";
import moment from "moment/moment";
import { Link } from "react-router-dom";

const ArticlesItem = (props) => {
  return (
    <>
      <div className="articlesItem-outer-container">
        <div className="articlesItem-inner-container">
          <Card
            hoverable
            className="articlesItem-card"
            cover={
              <img alt="article cover image" src={props.content.imageURL} />
            }
          >
            <div className="articlesItem-content-container">
              <div className="articlesItem-content-container-date">
                <CalendarOutlined
                  style={{ fontSize: "12px", color: "#f05742" }}
                />
                <span style={{ marginLeft: "10px" }}>
                  {/* {moment
                    .unix(props.content.publishedDate)
                    .format("MMMM Do YYYY")} */}
                  {props.content.date}
                </span>
              </div>
              <div className="articlesItem-content-container-title">
                {props.content.title}
              </div>
              <div className="articlesItem-content-container-description">
                {props.content.description}
              </div>
              <div className="articlesItem-content-container-button">
                <Link to={`/news/${props.content.url}`}>
                  Read More <RightOutlined style={{ fontSize: "10px" }} />
                </Link>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ArticlesItem;
