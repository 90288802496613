import {
  MdCrisisAlert,
  MdLocalOffer,
  MdOutlineSupportAgent,
  MdShare,
  MdTipsAndUpdates,
  MdUpgrade,
} from "react-icons/md";

export const whatsappCommerceContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "Elevate your business to new heights by tapping into a platform with over 2 billion users globally. Engage with your audience more effectively, accelerate your sales trajectory, and achieve superior customer support outcomes. Discover how our platform can be the catalyst for your business transformation, enabling you to connect with customers around the world and drive unprecedented growth.",
    id: "1",
    image: {
      img1: "/images/solutions/whatsappCommerce/2150535822.jpg",
      img2: "/images/solutions/whatsappCommerce/2150535824.jpg",
      img3: "/images/solutions/whatsappCommerce/2150535823.jpg",
      img4: "/images/solutions/whatsappCommerce/215053582.jpg",
      img5: "/images/solutions/whatsappCommerce/21505358215.jpg",
    },
    background: "/images/solutions/healthcare/banner.jpg",
    sectionTile: "",
    mainTitle: "Legal",
    title: "Revitalize your business on WhatsApp",
  },

  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "/images/solutions/whatsappCommerce/whatsappjwells.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Elevate Sales with WhatsApp Integration",
      description:
        "Effortlessly connect, sell, and manage your online orders via WhatsApp",
      descriptionOne:
        "Customer Experience Redefined: Simplify your sales process, enhance customer interaction, and reduce friction from start to finish.",
      descriptionTwo:
        "Customer Retention Campaigns: Draw customers back to your digital storefront with enticing promotions and updates on the latest products, all through WhatsApp.",
    },

    {
      id: "2",
      model: "model1",
      imageURL: "/images/solutions/whatsappCommerce/whatsappfood.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Streamline Your E-Commerce Workflow",
      description: "Automate your processes, focus on growth",
      descriptionTwo:
        "Customizable Automation: Set up personalized automations, workflows, and data conversions to refine your e-commerce practices and scale your business. ",
      descriptionOne:
        "Tech-Enabled Efficiency: Leverage automation tools like Zapier and Integromat for a seamless e-commerce operation, saving valuable time and resources.",
    },
    {
      id: "3",
      model: "model1",
      imageURL: "/images/solutions/whatsappCommerce/whatsapphealth.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Adaptive E-Commerce Communications",
      description: "Customize your outreach to fit every customer and platform",
      descriptionOne:
        "Flexible for All Business Models: Our platform is tailored to support diverse e-commerce strategies, from D2C to retail and marketplace collaborations.",
      descriptionTwo:
        "Smart Support Automation: Implement automated responses for frequent inquiries and sophisticated routing for complex issues to enhance support and sales team efficiency.",
    },
  ],
  features: [
    {
      title: "Share Your Catalog:",
      description:
        "Utilize WhatsApp to display your products and update customers on the latest stock information.",
      icon: <MdShare size={20} />,
    },
    {
      title: "Fast Response to Offers:",
      description:
        "Engage customers swiftly with promotions, leveraging WhatsApp's high message open rates.",
      icon: <MdLocalOffer />,
    },
    {
      title: "Confirmation & Support:",
      description:
        "Streamline customer interactions with automated order confirmations, billing updates, and support via no-code chatbots.",
      icon: <MdOutlineSupportAgent size={20} />,
    },
    {
      title: "Personalized Promotions:",
      description:
        "Deploy targeted WhatsApp campaigns based on customer purchase history and seasonal trends.",
      icon: <MdUpgrade size={20} />,
    },
    {
      title: "Loyalty Program Updates:",
      description:
        "Enroll customers in loyalty programs and inform them about points schemes and special offers through WhatsApp.",
      icon: <MdTipsAndUpdates />,
    },
    {
      title: "In Stock & New Products Alerts:",
      description:
        "Notify customers immediately about restocked items or new product launches.",
      icon: <MdCrisisAlert />,
    },
  ],
  stats: [
    {
      id: "1",
      count: "88",
      title: "decrease in reliance on human agents",
    },
    {
      id: "2",
      count: "90",
      title: "boost in 24/7 customer engagement",
    },
    {
      id: "3",
      count: "8",
      title: "enhancement in lead verification and qualification automation",
      countType: "x",
    },
    {
      id: "4",
      count: "8",
      title: "enhancement in lead verification and qualification automation",
      countType: "x",
    },
  ],
  innerFeatures: [
    {
      id: "1",
      title: "Smart Language Model",
      imageURL: "/images/solutions/healthcare/smart.svg",
      point1: "Enhanced Efficiency",
      point2: "24/7 Availability",
      point3: "Scalability and Consistency",
      point4: "Personalized and Contextualized Interaction",
    },
    {
      id: "2",
      title: "Powered by ChatGPT",
      imageURL: "/images/solutions/healthcare/ai.svg",
      point1: "State-of-the-art Natural Language Processing",
      point2: "Deep Learning Architecture",
      point3: "Large-Scale Pretraining",
      point4:
        "Advanced and Contextual Language Understanding and Generation Capabilities ",
    },
    {
      id: "3",
      title: "Secure and compliant conversations ",
      imageURL: "/images/solutions/healthcare/complaint.svg",
      point1: "Data Privacy",
      point2: "Regulatory Compliance",
      point3: "Secure Infrastructure",
      point4: "Encrypted Communication",
    },
  ],
  meta: {
    title:
      "iNextLabs WhatsApp Commerce - Transforming E-Commerce with WhatsApp",
    description:
      "Discover iNextLabs' WhatsApp Commerce solutions. Explore how we're revolutionizing e-commerce by leveraging the power of WhatsApp, allowing businesses to seamlessly connect with customers, showcase products, and enable transactions. Learn how you can enhance your online shopping experience through this innovative platform.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
