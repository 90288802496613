import { RiSurveyLine } from "react-icons/ri";
import {
  MdOutlineQuickreply,
  MdOutlineTroubleshoot,
  MdMiscellaneousServices,
  MdOutlineSupportAgent,
  MdOutlineFeedback,
} from "react-icons/md";
import { AiOutlineInteraction } from "react-icons/ai";
import { VscFeedback } from "react-icons/vsc";

export const CustomerSupportAutomationContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "Empowered by Generative AI, our platform offers a comprehensive suite for customer support, designed to streamline operations, enhance agent productivity, and elevate customer satisfaction and loyalty at scale.",
    id: "1",
    image: {
      img1: "/images/solutions/customer-support-automation/2149256146.jpg",
      img2: "/images/solutions/customer-support-automation/8500.jpg",
      img3: "/images/solutions/customer-support-automation/98535.jpg",
      img4: "/images/solutions/customer-support-automation/64426.jpg",
      img5: "/images/solutions/customer-support-automation/4093.jpg",
    },
    background: "/images/solutions/healthcare/banner.jpg",
    sectionTile: "",
    title: "AI-Enhanced Support Transforming Customer Experiences",
  },
  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "/images/solutions/customer-support-automation/50144.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "AI-Enhanced query resolution",
      description:
        "With iNextLabs, empower your support system using generative AI for effective data extraction and automation of common queries. Thanks to GPT-4 technology, it's possible to efficiently manage over 1,000 different queries, enhancing your ability to direct customer interactions smartly and reduce manual call handling.",
      descriptionOne: "Intelligent routine query handling",
      descriptionTwo: "Enhanced call deflection mechanisms",
      descriptionThree: "Cognitive data extraction capabilities",
    },

    {
      id: "2",
      model: "model1",
      imageURL: "/images/solutions/customer-support-automation/28409.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Generative AI support desk",
      description:
        "Elevate your customer service experience by integrating advanced AI models, including ChatGPT, to offer quick and precise issue resolutions. This setup supports a higher deflection rate, allowing for the automatic resolution of standard inquiries and enabling the system to scale as your customer base grows, all while keeping satisfaction levels high. ",
      descriptionOne: "Accelerated customer resolutions",
      descriptionTwo: "High deflection rate with AI",
      descriptionThree: "Scalable support solutions",
    },
    {
      id: "3",
      model: "model1",
      imageURL: "/images/solutions/customer-support-automation/170.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Scalable support solutions",
      description:
        "Our tool facilitates the creation of a seamless and engaging chat and voice bot. By integrating automated workflows and leveraging AI for conversation suggestions, you can customize customer interactions to be highly efficient and personalized. This enhances engagement and supports key business goals effectively.",
      descriptionOne: "Simplified bot development",
      descriptionTwo: "Automated conversational flows",
      descriptionThree: "AI-generated dialogue suggestions",
    },
    {
      id: "4",
      model: "model1",
      imageURL: "/images/solutions/customer-support-automation/132314.jpg",
      pointer: "/images/icons/pointer.svg",
      title: "Conversational Analytics Optimization",
      description:
        "Enhance your customer interaction strategies with detailed AI analytics. Monitoring essential metrics like deflection rates, CSAT scores, and response times helps you understand the effectiveness of your support and identify areas for improvement.",
      descriptionOne: "Actionable customer interaction data",
      descriptionTwo: "Performance metrics analysis",
      descriptionThree: "Strategic support optimization",
    },
  ],
  ValueProposition: {
    title: "Customer-Centric Support Solutions",
    tagline:
      "Empower Support, Elevate Experience, and Enhance Efficiency with AI",

    content: [
      {
        id: "1",
        title: "Personalized Interactions",
        tagline:
          "Customize communications to align with each customer's history and preferences, fostering a more engaging and meaningful experience.",
        icon: <AiOutlineInteraction size={30} />,
      },
      {
        id: "2",
        title: "Issue Resolution",
        tagline:
          "Address and resolve customer issues swiftly to maintain satisfaction and trust, enhancing the overall service experience.",
        icon: <MdOutlineTroubleshoot size={30} />,
      },
      {
        id: "3",
        title: "Instant Answering",
        tagline:
          "Deliver quick responses to customer queries, demonstrating efficiency and reducing wait times for improved support satisfaction.",
        icon: <MdOutlineQuickreply size={27} />,
      },
      {
        id: "4",
        title: "Feedback Collection",
        tagline:
          "Systematically collect and assess customer feedback to guide improvements in products and services, ensuring continuous enhancement.",
        icon: <MdOutlineFeedback size={30} />,
      },
      {
        id: "5",
        title: "Seamless Self-Service",
        tagline:
          "Provide straightforward self-service options, enabling customers to solve problems independently, which streamlines support and increases efficiency.",
        icon: <MdMiscellaneousServices size={30} />,
      },
      {
        id: "6",
        title: "Proactive Support",
        tagline:
          "Anticipate and address customer needs before they escalate, showing foresight in customer care and building stronger relationships.",
        icon: <MdOutlineSupportAgent size={30} />,
      },
    ],
  },

  integrations: {
    title: "We operate alongside your workflow",
    description:
      "Centralize all your tools and data effortlessly using simple integrations that connect your helpdesk software, CRM, order management, payment systems, and more.",
    content: [
      {
        id: "1",
        title: "Zendesk",
        imageURL: "/images/integration-icons/zendesk.png",
      },
      {
        id: "2",
        title: "Freshdesk",
        imageURL: "/images/integration-icons/freshdesk.png",
      },

      {
        id: "3",
        title: "Intercom",
        imageURL: "/images/integration-icons/intercom.png",
      },
      {
        id: "4",
        title: "Salesforce Service Cloud",
        imageURL: "/images/integration-icons/salesforce.png",
      },
      {
        id: "5",
        title: "Help Scout",
        imageURL: "/images/integration-icons/help-scout.png",
      },
      {
        id: "6",
        title: "HubSpot Service Hub",
        imageURL: "/images/integration-icons/hubspot.png",
      },
      {
        id: "7",
        title: "LiveAgent",
        imageURL: "/images/integration-icons/live-agent.png",
      },
      {
        id: "8",
        title: "Zoho Desk",
        imageURL: "/images/integration-icons/zoho.svg",
      },
      {
        id: "9",
        title: "Kayako",
        imageURL: "/images/integration-icons/kayako.png",
      },
      {
        id: "10",
        title: "HappyFox",
        imageURL: "/images/integration-icons/happy-fox.png",
      },
    ],
  },
  FAQ: [
    {
      id: "1",
      question: "1. How does AI transform customer support operations?",
      answer:
        "AI revolutionizes customer support operations by automating routine inquiries, providing personalized assistance, and offering actionable insights for optimizing support strategies, ultimately enhancing efficiency and customer satisfaction.",
    },
    {
      id: "2",
      question: "2. What advantages does AI-powered customer support offer?",
      answer:
        "AI-powered customer support brings numerous benefits such as faster response times, personalized interactions, improved issue resolution, enhanced scalability, and better utilization of support resources, resulting in higher customer loyalty and retention.",
    },

    {
      id: "3",
      question:
        "3. How does the Virtual Assistant from iNextLabs enhance customer support automation?",
      answer:
        "iNextLabs' Virtual Assistant streamlines customer support by automating responses to common queries, assisting with issue resolution, and analyzing customer interactions. This improves response efficiency, reduces wait times, and enables support teams to focus on complex inquiries, thereby elevating overall support effectiveness.",
    },
    {
      id: "4",
      question:
        "4. Can AI-driven support solutions provide personalized customer interactions?",
      answer:
        "Absolutely, AI-driven support solutions excel in delivering personalized customer interactions. By analyzing customer data and behavior, these solutions tailor responses and recommendations to individual needs, ensuring a more engaging and satisfying support experience for each customer.",
    },
  ],

  innerFeatures: [
    {
      id: "1",
      title: "Smart Language Model",
      imageURL: "/images/solutions/healthcare/smart.svg",
      point1: "Enhanced Efficiency",
      point2: "24/7 Availability",
      point3: "Scalability and Consistency",
      point4: "Personalized and Contextualized Interaction",
    },
    {
      id: "1",
      title: "Powered by ChatGPT",
      imageURL: "/images/solutions/healthcare/ai.svg",
      point1: "State-of-the-art Natural Language Processing",
      point2: "Deep Learning Architecture",
      point3: "Large-Scale Pretraining",
      point4:
        "Advanced and Contextual Language Understanding and Generation Capabilities ",
    },
    {
      id: "1",
      title: "Secure and compliant conversations ",
      imageURL: "/images/solutions/healthcare/complaint.svg",
      point1: "Data Privacy",
      point2: "Regulatory Compliance",
      point3: "Secure Infrastructure",
      point4: "Encrypted Communication",
    },
  ],
  stats: {
    title:
      "Future-proof your support strategies today, achieving greater efficiency",
    description:
      "Customer support automation using Generative AI enhances 24/7 service availability and personalizes interactions, reducing costs and improving efficiency for scalable, reliable customer experiences.",
    content: [
      {
        id: "1",
        title: "of queries handled through self-service",
        count: 85,
      },
      {
        id: "2",
        title: "boost in agent productivity",
        count: 50,
      },
      {
        id: "3",
        title: "reduction in operational costs",
        count: 70,
      },
      {
        id: "4",
        title: "increase in customer satisfaction (CSAT)",
        count: 50,
      },
    ],
  },
  meta: {
    title: "Customer Support Automation Solutions - iNextLabs",
    description:
      "Discover customer support automation solutions at Inext Labs. Enhance efficiency and customer satisfaction with advanced AI technologies. Learn more!",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
