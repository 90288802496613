import React from "react";
import Footer from "../../footer/Footer";
import Meta from "../../Meta";
import Filler from "../../header/Filler";
import HomeBanner from "../../banner/HomeBanner";
import InnerPageValueProps from "../../innerpageswimlane/InnerPageValueProps";
import InnerPageFeatures from "../../features/InnerPageFeatures";
import Review from "../../review/Review";
import Clients from "../../clients/Clients";
import { homeContent } from "../../home/HomeContent";
import { humanresourcesContent } from "./humanResourcesContent";
import Header from "../../header/Header";
import SolutionBanner from "../banner/SolutionBanner";
import CounterStats from "../../stats-counter/Stats-counter";
import ValueProposition from "../../value-proposition/ValueProposition";
import IntegrationSection from "../integration/IntegrationSection";
import AccordionSection from "../../accordion/Accordion";
import DiscoveryWorkshop from "../../discovery-workshop/DiscoveryWorkshop";
import WebinarSection from "../webinar/WebinarSection";

const HumanResources = () => {
  return (
    <>
      <Meta
        title={humanresourcesContent.meta.title}
        description={humanresourcesContent.meta.description}
        image={humanresourcesContent.meta.image}
        locale={humanresourcesContent.meta.locale}
        siteName={humanresourcesContent.meta.siteName}
        type={humanresourcesContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      {/* <HomeBanner content={humanresourcesContent.banner} /> */}
      <SolutionBanner content={humanresourcesContent.banner} />
      <CounterStats content={humanresourcesContent.stats} />
      <InnerPageValueProps content={humanresourcesContent.valueProps} />
      <ValueProposition content={humanresourcesContent.ValueProposition} />
      <IntegrationSection content={humanresourcesContent.integrations} />
      <DiscoveryWorkshop />
      {/* <WebinarSection /> */}
      <AccordionSection content={humanresourcesContent.FAQ} />
      <Footer />
    </>
  );
};

export default HumanResources;
