import React from "react";
import Divider from "../Divider";

const ValueProposition = (props) => {
  return (
    <>
      <div className="outerContainer value-proposition-outer-container">
        <div className="innerContainer">
          <div className="value-proposition-header">
            <h2 className="value-proposition-header-title sectionTitle">
              {props.content.title}
            </h2>
            <p className="value-proposition-header-tagline para center">
              {props.content.tagline}
            </p>
            <Divider />
            <div
              className="value-proposition-content-wrapper"
              style={{
                gridTemplateColumns:
                  props.content.content.length === 4
                    ? "repeat(4, 1fr)"
                    : "repeat(3, 1fr)",
              }}
            >
              {props.content.content.map((value) => (
                <div
                  className="value-proposition-content "
                  id={"value-proposition" + value.id}
                  key={value.id}
                  style={value.points && { height: "auto" }}
                >
                  <div className="value-proposition-content-icon">
                    {value.icon}
                  </div>
                  <div className="value-proposition-content-title">
                    <h3>{value.title}</h3>
                  </div>
                  <div className="value-proposition-content-description">
                    <p>{value.tagline}</p>
                  </div>
                  {value.points && (
                    <ol>
                      {Object.values(value.points).map((point, index) => (
                        <div className="value-proposition-content-point">
                          <li key={index} className="para left">
                            {point}
                          </li>
                        </div>
                      ))}
                    </ol>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ValueProposition;
