export const aboutContent = {
  banner: {
    button: {
      text: "Start Your Free Trial",
      url: "/sign-up",
    },
    description: "Redefining the Future with AI ",
    id: "1",
    sectionTile: "",
    title: "Welcome to iNextLabs",
    videoURL:
      "https://inextlabswebassets.blob.core.windows.net/video/_import_614eee66b667f2.55953610.mp4",
  },
  teams: [
    {
      name: "Sachin Hridayraj",
      jobTitle: "CoFounder",
      avatar: "/images/company/teams/Sachin-Pic.png",
    },
    {
      name: "Murugappan",
      jobTitle: "CoFounder, Head of Engineering",
      avatar: "/images/company/teams/Muru-Pic.png",
    },
    {
      name: "Shanmuga Ganesan",
      jobTitle: "Managing Director, Malaysia",
      avatar: "/images/company/teams/shan.jpg",
    },
    {
      name: "Frank Teh",
      jobTitle: "Advisor, Enterprise Strategy",
      avatar: "/images/company/teams/frank.jpeg",
    },
    {
      name: "Annamalai Meyyappan",
      jobTitle: "Advisor, Finance",
      avatar: "/images/company/teams/annamalai.jpeg",
    },
  ],
  partners: {
    title: "Our Esteemed Partners and Recognitions",
    logos: [
      {
        id: "1",
        imageURL: "/images/company/aboutus/founders-hub.svg",
      },
      {
        id: "2",
        imageURL:
          "/images/company/aboutus/Microsoft_SolutionsPartner_DataAIAzure.svg",
      },
      {
        id: "3",
        imageURL: "/images/company/aboutus/md.svg",
      },
      {
        id: "4",
        imageURL: "/images/company/aboutus/ai-verify.svg",
      },
      {
        id: "5",
        imageURL: "/images/company/aboutus/salesforce-logo.svg",
      },
    ],
  },
  coreValues: [
    {
      id: "",
      title: "Customer Obsessed",
      imageURL: "/images/company/obession.svg",
      descriptionOne: "Customer satisfaction is our #1 priority.",
      descriptionTwo:
        "We strive to ensure that we meet the requirements of our customer without any compromise on quality",
    },
    {
      id: "2",
      title: "Problem Solving",
      imageURL: "/images/company/solution.svg",
      descriptionOne:
        "We are passionate about adding value and problem solving for our customers.",
      descriptionTwo:
        "We constantly thrive to explore new possibilities for our customers to accelerate and innovate their business​",
    },
    {
      id: "3",
      title: "Integrity and Trust",
      imageURL: "/images/company/verified.svg",
      descriptionOne: "Integrity and Trust are at the core of what we do.",
      descriptionTwo:
        "Complete transparency, mutual respect and trust with employees and clients are our key driving principles​",
    },
    {
      id: "4",
      title: "Innovation",
      imageURL: "/images/company/verified.svg",
      descriptionOne: "We embrace innovation to drive growth and efficiency.",
      descriptionTwo:
        "We continuously seek out new technologies and methodologies to improve our products and services, ensuring we stay at the forefront of AI advancements.",
    },
  ],
  locations: [
    {
      id: "1",
      city: "Singapore",
      imageURL: "/images/company/obession.svg",
      address: "1 Coleman Street,#10-06 The Adelphi, Singapore 179803.",
      phoneNumber: "+6592727331",
    },
    {
      id: "2",
      city: "India",
      imageURL: "/images/company/solution.svg",
      address:
        "A1, Visaka Flat, No 96, Chandrasekarapuram 2nd Street, Ambattur, Chennai 600053, India",
      phoneNumber: "+91 74183 46460​",
    },
    {
      id: "3",
      city: "USA",
      imageURL: "/images/company/verified.svg",
      address: "1001 E Hebron Parkway Suite 118-291 Carrollton, TX 75010-1015",
    },
    {
      id: "3",
      city: "Malaysia",
      imageURL: "/images/company/verified.svg",
      address:
        "iNextLabs (M) Sdn Bhd, 13th Floor, Tower 4, PFCC, Jalan Puteri 1/2, Bandar Puteri Puchong, 47100 Puchong, Selangor, Malaysia",
    },
  ],

  meta: {
    title: "About iNextLabs | Innovation in AI Solutions",
    description:
      "Learn about iNextLabs, a leader in AI solutions. Discover our mission, team, and commitment to driving innovation across industries.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
