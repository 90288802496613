import React from "react";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import Meta from "../../Meta";
import CounterStats from "../../stats-counter/Stats-counter";
import { inflowContent } from "./inflowContent";
import InnerPageValueProps from "../../innerpageswimlane/InnerPageValueProps";
import ValueProposition from "../../value-proposition/ValueProposition";
import IntegrationSection from "../../solutions/integration/IntegrationSection";
import WebinarSection from "../../solutions/webinar/WebinarSection";
import AccordionSection from "../../accordion/Accordion";
import ProductsBanner from "../banner/ProductsBanner";
import CustomerStories from "../../solutions/customer-stories/CustomerStories";
import PriceCard from "../../price-card/PriceCard";

const InFlow = () => {
  return (
    <>
      <Meta
        title={inflowContent.meta.title}
        description={inflowContent.meta.description}
        image={inflowContent.meta.image}
        locale={inflowContent.meta.locale}
        siteName={inflowContent.meta.siteName}
        type={inflowContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <ProductsBanner content={inflowContent.banner} />
      <CounterStats content={inflowContent.stats} />
      <InnerPageValueProps content={inflowContent.valueProps} />
      <ValueProposition content={inflowContent.ValueProposition} />
      <IntegrationSection content={inflowContent.integrations} />
      <PriceCard />
      <CustomerStories />
      {/* <WebinarSection /> */}
      <AccordionSection content={inflowContent.FAQ} />
      <Footer />
    </>
  );
};

export default InFlow;
