import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import TestimonialItem from "./TestimonialItem";
import Divider from "../Divider";
const TestiMonial = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 500,
      once: false,
    });
  }, []);
  const TestimonialContent = props.content;
  return (
    <>
      <div className="outerContainer">
        <div
          className="innerContainer"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className="testimonial-inner-container">
            <div className="testimonial-title-container">
              <div className="testimonial-section-title">Testimonial</div>

              <h2 className="testimonial-title">What Our Customers Say</h2>
            </div>
            <Divider />
            <div className="testimonial-list-container">
              <img
                src="\images\overlay-styles\Group.svg"
                alt="testimonial logo"
                id="testimonial-overlay-logo"
              />
              {TestimonialContent.map((testimonial, index) => (
                <TestimonialItem key={index} content={testimonial} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestiMonial;
