import React from 'react'
import Header from "../header/Header";
import Filler from '../header/Filler';
import Footer from '../footer/Footer';
import Meta from '../Meta';

const TermsOfService = () => {
  return (
    <div>
            <Meta title="iNextLabs - Terms of Services" description="Using this website, we assume that you accept our terms and conditions. These terms and conditions apply to all users, visitors, and individuals who access our site." noindex={false} />

            <Header />
            <Filler />
            
            <div className="outerContainer">
                <div className="innerContainer left">
                    <h1 className="bannerTitle">Terms of Service</h1>
                    <p className="para">THIS IS AN AGREEMENT BETWEEN THE CUSTOMER OR THE ENTITY THE CUSTOMER REPRESENT (hereinafter “CUSTOMER”) AND INEXTLABS PTE LTD (hereinafter “INEXTLABS”) GOVERNING CUSTOMER USE OF INEXTLABS CUSTOMER EXPERIENCE SOFTWARE. </p>
                    <p className="para">INEXTLABS and the CUSTOMER shall hereinafter be referred to, individually as a “Party” and collectively as the “Parties”.</p>
                    
                    <div className="para"><b>WHEREAS:</b></div>
                    <p className="para">
                        <ol>
                            <li type="A">INEXTLABS is engaged in the business of providing to its end user, a cloud-based software solutions, to communicate with customers, to improve the productivity and to automate business processes/workflows for efficiency through Chatbots powered by Artificial Intelligence.</li>
                            <li type="A">In pursuance to the above, iNextLabs and the Customer have agreed to enter into this Agreement.</li>
                        </ol>
                    </p>
                    <p className="para">NOW THEREOF in consideration and in accordance with the terms and conditions herein contained the mutual benefits to be derived therefrom and other good and valuable consideration, the sufficiency of which is hereby acknowledged, the Parties agree as follows:</p>

                    <ol className="TOSol">
                        <li className="TOSli paragraphTitle">DEFINITIONS AND INTERPRETATIONS
                                <ol className="TOSol">
                                    <li className="TOSli para">
                                        Definitions. In this Agreement except where the context otherwise requires, the following words and expressions shall have the following meanings:
                                        <br></br><br></br>
                                        <p><b>“Agreement”</b> shall mean this Agreement in its entirety, including all the annexure attached to or referenced to this Agreement and any other document pursuant to this Agreement and shall include any amendment(s), addendum(s), annexure(s) thereto, from time to time.</p>
                                        <p><b>“Applicable Laws”</b> includes (a) statute, statutory Instrument, bye-laws, order, directive, decree or law (including any common law, judgment, demand, order or decision of any court, regulator or tribunal); (b) rule, policy, guidance or recommendation issued by any governmental, statutory or regulatory body including current circulars and gazette orders/amendments made from time to time thereunder;</p>
                                        <p><b>“Channel”</b> shall mean third party service provider.</p>
                                        <p><b>“Cloud”</b> shall refer to servers that are accessed over the Internet and Cloud under this Agreement shall mean but not limited to Microsoft Azure and Google Cloud Platform </p>
                                        <p><b>“Confidential Information”</b> shall mean any and every including any proprietary information of the Disclosing Party, including but not limited to the terms of this Agreement, commercial, technical, and artistic information relating to Disclosing Party’s establishment, business, operation, maintenance, marketing and promoting of its own services and products, programming techniques, experimental work, customers, clients, developments, inventions, technology etc. Confidential Information will include all information (whether available in written, oral or machine-readable form) relating to the business or affairs of the Disclosing Party, including (without limitation) information relating to existing or future systems, software, hardware, products and services, and those in development, and accompanying marketing plans, details of employees and business strategies etc; </p>
                                        <p><b>“Charges”</b> means any charges agreed by the Parties under the relevant commercial terms and which are payable by the Customer to iNextLabs in consideration of the Services. </p>
                                        <p><b>“Data Controller”</b> shall mean any person or an entity who controls and owns the data which shall include personal information on behalf of the Data Processor.  </p>
                                        <p><b>“Data Processor”</b> shall mean any person or an entity who shall only process the data and have no control or ownership on such data processed.  </p>
                                        <p><b>“Error(s)”</b> means any defect, error, non-conformity, malfunction, lack of performance of the solution. </p>
                                        <p><b>“Force Majeure”</b> means any event or sequence of events beyond a party’s reasonable control which could not have been reasonably anticipated or avoided, which prevents or delays the performance of its obligations under this Agreement. Force majeure includes natural disaster, armed conflict, civil unrest, insurrection, acts of terrorism, fire or explosion, labour disputes, strikes and similar industrial actions, epidemic, pandemic, or governmental actions.  </p>
                                        <p><b>“Intellectual Property Rights”</b> means collectively, all patent, copyright, design right, trademark, trade secret, moral and all other intellectual property rights in and to the Solution and/or the Services whether arising by operation of law, contract, license, or otherwise.  </p>
                                        <p><b>“Internet”</b> means the computer and telecommunications facilities that comprise the interconnected worldwide network of networks that employ the transmission control protocol or internet protocol, or any predecessor or successor protocol, to communicate information of all kinds by wire. </p>
                                        <p><b>“Loss”</b> means any suits, claims, losses, liabilities, damages, judgments, settlements, costs and expenses, including, but not limited to, reasonable attorneys’ fees and other legal fees as awarded by a court of final appeal.  </p>
                                        <p><b>“Person”</b> shall mean a juristic person, individual, company, corporation, partnership, association, trust or other entity or organisation, limited liability partnership including a Government or political subdivision or an agency or instrumentality thereof; </p>
                                        <p><b>“Personal data”</b> shall mean the data that allows someone to identify or contact, including, for example, end user’s name, address, telephone number, e-mail address, government-issued identification information, education information, payroll information, as well as any other non-public information about the end user that is associated with or linked to any of the foregoing data. </p>
                                        <p><b>“Product”</b> shall mean iNotify, iBotify, software applications and other products, service and devices provided by iNextLabs. </p>
                                        <p><b>“Services”</b> means licensing of the Solution, customisation and such other services provided by iNextLabs to the Customer as mentioned under Clause 2 of this Agreement. </p>
                                        <p><b>“Solution”</b> means the stored value asset management solution provided by iNextLabs to the Customers; and  </p>
                                        <p><b>“Third Party Service Provider”</b> shall mean the Channels through which iNextLabs provide services which may include but not limited to WhatsApp, Facebook Messenger and Twilio.  </p>
                                        <p><b>“Territory”</b> shall mean the place of incorporation of iNextLabs and the place of operation of the Customer.   </p>
                                        <p><b>“Website”</b> or <b>“iNextlabs Website”</b> shall mean https://www.inextlabs.com/  </p>
                                    </li>
                                    <li className="TOSli para">Interpretation. Unless the context of this Agreement otherwise requires, this Agreement shall be interpreted as follows:
                                        <ol>
                                            <li type="i">Clauses, annexure and paragraph heading shall not affect the interpretation of this Agreement.</li>
                                            <li type="i">Annexure shall form an integral part of this Agreement and shall have effect as if set out in this Agreement; </li>
                                            <li type="i">words in the singular shall include the plural and vice versa; </li>
                                            <li type="i">words denoting the masculine gender shall include the feminine gender and neuter gender; </li>
                                            <li type="i">where the words “include(s)”, “including” or “in particular” are used in this Agreement, they shall be deemed to have the words without limitation following them. Where the context permits, the words “other” and “otherwise” are illustrative and shall not limit the sense of the words preceding them; </li>
                                            <li type="i">a reference to a statute or statutory provision shall be deemed a reference to it as it is in force for the time being, taking account of any changes and/or amendments thereto and shall include any subordinate legislation for the time being in force made under it; </li>
                                            <li type="i">a reference to  writing or written shall include email; </li>
                                            <li type="i">any obligation in this Agreement on a person not to do something shall include an obligation not to agree, allow, permit or acquiesce in that thing being done; </li>
                                            <li type="i">references to clauses, annexures and paragraphs shall be deemed as references to the clauses, annexures and paragraphs of this Agreement; </li>
                                            <li type="i">a disclosure of an item in writing referring to a specific clause of this Agreement or a specific paragraph of the Annexure shall be deemed to be a disclosure only for the purpose of that clause or paragraph and not for the purpose of any other provision of this Agreement;  </li>
                                            <li type="i">where a word or phrase is defined, other parts of speech and grammatical forms of that word or phrase shall have the corresponding meanings; and </li>
                                            <li type="i">time is of the essence in the performance of the Parties respective obligations; if any time period specified herein is extended in terms of this Agreement, such extended time shall also be of the essence. </li>
                                        </ol>
                                    </li>
                                </ol>
                        </li>
                        <li className="TOSli paragraphTitle">SCOPE OF SERVICE
                            <p className="para">Subject to the payment of the Charges, iNextLabs shall provide the following services <b>(“Services”)</b></p>
                            <p className="para">
                                <ol>
                                    <li type="i">iNextLabs shall enable the Customers with access to the Solution on the iNextLabs Website, such access being limited to the technical interface.</li>
                                    <li type="i">iNextLabs shall maintain the latest version of the Solution and software available for the Customer over the Internet as a SaaS model. </li>
                                    <li type="i">The responsibilities of the Customer and/or requirements for the support of this Agreement shall include payment of costs as stipulated in the <b>Annexure-A. </b></li>
                                </ol>
                            </p>
                        </li>
                        <li className="TOSli paragraphTitle">PAYMENT AND PRICING
                            <p className="para">
                                <ol className="TOSol">
                                    <li className="TOSli para">The Services are available to the Customer under monthly/yearly subscription plans of various durations. Payments for subscription plans is made only by Credit Card. The subscription will be automatically renewed at the end of each subscription period. If the Customer do not wish to renew the subscription, the Customer must inform us at least seven days prior to the renewal date. From time to time, iNextLabs may change the Charges depending upon the pricing of the Third Party Service Providers which shall include but not limited to change in plan and features offered by Third Party Service Providers and inform the same to the Customer prior to such change. Increase in Charges shall not apply until the expiry of the Customer’s current billing cycle.</li>
                                    <li className="TOSli para">Subscriptions charged are not refundable. If iNextLabs decides to refund then pro-rata calculation will be applied for refund. </li>
                                </ol>
                            </p>    
                        </li>
                        <li className="TOSli paragraphTitle">DATA PRIVACY
                            <p className="para">
                                <ol className="TOSol">
                                    <li className="TOSli para">The Parties hereby agree and acknowledge that certain Personal Data may be disclosed and/or exchanged between the Parties during provision of Services under this Agreement. In the event such Personal Data is disclosed hereunder, each Party shall, subject to the terms of this Agreement comply with and undertake the obligations in compliance with all Applicable Laws to the protection of Personal Data, including but not limited to The Personal Data Protection Act 2012 (“PDPA”).  </li>
                                    <li className="TOSli para">iNextLabs undertake to respect their duties as Data Processor only and as defined in the Applicable Laws.  </li>
                                    <li className="TOSli para">iNextLabs may have to implement or have access to Personal Data processing on behalf of the Customer, who shall remain the Data Controller for such data processing. iNextLabs shall only intervene in Personal Data processing on the instructions of the Customer and undertake to take all necessary precautions to conserve the security of the data and to prevent that such data be distorted, damaged, or that unauthorised third parties gain access to it.</li>
                                </ol>
                            </p>    
                        </li>
                        <li className="TOSli paragraphTitle">INDEMNITY, LIMITATION OF LIABILITY
                            <p className="para">
                                <ol className="TOSol">
                                    <li className="TOSli para">Each Party shall indemnify, defend and hold harmless the other Party from and against any and all third-party liability and Losses actually suffered by such other Party, relating to or arising out of the breach of this Agreement or the negligence or willful misconduct of the Customer or its employees or agents. </li>
                                    <li className="TOSli para">Notwithstanding anything to the contrary, neither Party shall be liable to the other Party for any indirect, incidental, consequential, special, exemplary or other damages, including but not limited to loss of business profits, loss of business information, business interruption and the like, suffered by the other Party under or in pursuance of the terms hereof, howsoever arising, whether under contract, tort or otherwise, even if advised about the possibility of the same. </li>
                                    <li className="TOSli para">Notwithstanding anything to the contrary, the liability of iNextLabs shall be limited to the Charges paid by the Customer for the last billing cycle. </li>
                                </ol>
                            </p>    
                        </li>
                        <li className="TOSli paragraphTitle">REPRESENTATION AND WARRANTIES 
                            <p className="para">
                                <ol className="TOSol">
                                    <li className="TOSli para">Each Party hereby represents and warrants to the other that: 
                                        <p className="para">
                                            <ol>
                                                <li type="i">it has been duly incorporated and organized, and is validly existing in good standing, under the Applicable Laws; </li>
                                                <li type="i">it has the corporate power and authority to enter into and be bound by this Agreement;</li>
                                                <li type="i">it has all material permits, approvals, authorizations, licenses, registrations, and consents including registrations necessary for the purposes of this Agreement and for the conduct of their respective businesses; and</li>
                                                <li type="i">the execution and delivery of this Agreement will not: (i) result in a breach of any provision of its charter documents; (ii) result in a breach of, or constitute a default under, any agreement or arrangement by which it is bound; (iii) result in a breach of any order, judgment or decree of any court or governmental agency to which the party is bound; and (iv) violate any Applicable Law. </li>
                                            </ol>
                                        </p>
                                    </li>
                                    <li className="TOSli para">Customer expressly understands and agrees that the use of the Services is at their sole risk and the Services are provided on an as-is-and-as-available basis.  
                                        <p className="para">
                                            <ol>
                                                <li type="i">iNextLabs expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability and fitness for a particular purpose.  </li>
                                                <li type="i">iNextLabs makes no warranty that the Services will be uninterrupted, timely, secure, or error-free. </li>
                                                <li type="i">Use of any material downloaded or obtained through the use of the Services shall be at the Customer’s own discretion and risk and the Customer will be solely responsible for any damage to Customer computer system, mobile telephone, wireless device or data that results from the use of the Services or the download of any such material.  </li>
                                                <li type="i">No advice or information, whether written or oral, obtained by the Customer from iNextLabs or its employees or representatives shall create any warranty not expressly stated in the terms. </li>
                                            </ol>
                                        </p>                                    
                                    </li>
                                </ol>
                            </p>    
                        </li>
                        <li className="TOSli paragraphTitle">CONFIDENTIALITY 
                            <p className="para">
                                <ol className="TOSol">
                                    <li className="TOSli para">Each Party shall treat any data and information, whether written, oral or visual, disclosed to it or which comes into its possession or knowledge in connection with this Agreement as confidential and shall not disclose the same to any others, except as may be required by law or as may be required to be disclosed to employees and personnel who execute suitable non-disclosure agreements for the same. The provisions of this Clause shall not apply to disclosure of confidential information that (i) was publicly known and made generally available in the public domain prior to the time of disclosure by the disclosing party; (ii) becomes publicly known and made generally available after disclosure by the disclosing party to the receiving party without any breach by the receiving party of its obligations hereunder; (iii) is already in the possession of the receiving party at the time of disclosure by the disclosing party, as shown by the receiving party's files and records, immediately prior to the time of disclosure; (iv) is obtained by the receiving party from a third party lawfully in possession of such information and without a breach of such third party's obligations of confidentiality; or (v) is independently developed by the receiving party without use of or reference to the disclosing party's confidential information, as shown by documents and other competent evidence in the receiving party's possession.  </li>
                                    <li className="TOSli para">The Parties agree to keep in the strictest confidence and not disclose to any third party, the commercial terms agreed by the parties herein and the terms and conditions set out in this Agreement, for any purpose whatsoever. </li>
                                </ol>
                            </p>    
                        </li>
                        <li className="TOSli paragraphTitle">PROPRIETARY RIGHTS
                            <p className="para">
                                <ol className="TOSol">
                                    <li className="TOSli para">The Solution and iNextLabs’ structure, organization and source code constitute valuable trade secrets of iNextLabs. All worldwide intellectual property rights in the Services and Solution are the exclusive property of iNextLabs. All rights not expressly granted to Customer are reserved by iNextLabs.</li>
                                    <li className="TOSli para">iNextLabs grants to the Customer a non-exclusive, non- transferable, limited, temporary, worldwide, right to use the Solution in accordance with the terms of this Agreement for the term of the Agreement. </li>
                                    <li className="TOSli para">Customer will use the Solution strictly in accordance with the terms hereof and will not permit others to use the same. The Customer will not and will not permit others to: (i) copy, adapt, alter, modify, merge or create derivative works of the Solution; (ii) reverse-engineer, disassemble, or decompile the Solution or otherwise attempt to determine its underlying source code; (iii) sell, lease, sub-license or provide any portion of the Solution to any third party; or (iv) use the software applications in any manner of service bureau, out-sourcing or other arrangement to process or administer data on behalf of any third party.  </li>
                                    <li className="TOSli para">iNextLabs shall not be liable to the Customer if any infringement or misappropriation claim results from: (i) a correction or modification of the Solution, which is not provided by iNextLabs; or (ii) the combination of the Solution with other non-iNextLabs’s software (unless such combination was expressly permitted by iNextLabs in writing). This Clause 8.4 states the entire obligation of iNextLabs and the exclusive remedies of the Customer with respect to any claims of infringement or misappropriation of third party rights.  </li>
                                    <li className="TOSli para">The Customer hereby grants iNextLabs the right to use and display the Customer’s trademarks and service marks that are applicable to under this Agreement in iNextLabs Website. </li>
                                    <li className="TOSli para">Both Parties agree that all Intellectual Property, including any brands/logos, trademarks, etc., belonging to each Party are the exclusive property of the respective Party and, except as provided in this Agreement, cannot in any circumstances be used, or copied, or altered in any manner. Further, except as provided in this Agreement or permitted under Applicable Laws, neither Party may use any Intellectual Property, including any brands/logos, or trademarks, which is identical/ similar the brands/logos/trademarks of the other Party without being specifically authorized in writing by that other Party. </li>
                                </ol>
                            </p>    
                        </li>
                        <li className="TOSli paragraphTitle">INFORMATION SECURITY 
                            <p className="para">
                                <ol className="TOSol">
                                    <li className="TOSli para">iNextLabs shall take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store Personal Data. </li>
                                    <li className="TOSli para">All information gathered by iNextLabs is securely stored within the iNextLabs controlled database. The database is stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited. However, as effective as iNextLabs security measures are, no security system is impenetrable. iNextLabs cannot guarantee the security of the database, nor can we guarantee that information the Customer supply will not be intercepted while being transmitted to us over the Internet.  Any information the Customer include in a posting to the discussion areas is available to anyone with Internet access. </li>
                                    <li className="TOSli para">iNextLabs shall deploy anti-virus software and scan all iNextLabs Solution for suspicious traffic and viruses. </li>
                                </ol>
                            </p>    
                        </li>
                        <li className="TOSli paragraphTitle">BRAND LICENSE
                            <p className="para">The Customer hereby, as on and with effect from the date hereof, grants to iNextLabs a non-exclusive, non-transferable, non-sublicensable right and must be limited to the Territory and license to use the Customer’s trademark and logo with respect to the services to be provided by iNextLabs, in accordance with the terms hereof. </p>
                        </li>
                        <li className="TOSli paragraphTitle">COMMENCEMENT, TERM AND TERMINATION 
                            <p className="para">
                                <ol className="TOSol">
                                    <li className="TOSli para">This Agreement shall be effective shall be valid, binding through and co-terminus with the subscription period (“Term”). Upon expiry thereof, the Parties agree that the Term of this Agreement shall stand automatically renewed along with the subscription period as agreed mutually by the Parties, unless terminated by either Party in accordance with this Clause. </li>
                                    <li className="TOSli para">The Customer shall accept the terms by checking a checkbox indicating the acceptance of the terms or by using the Service.  </li>
                                    <li className="TOSli para">Upon completion of the Term, either Party may terminate this Agreement and/or its subscription under this Agreement, without cause, by giving 1 (one) months’ notice to the other Party in writing.  </li>
                                    <li className="TOSli para">This Agreement may be terminated immediately for cause by either party in the event the other party is in breach of any of its obligations hereunder and fails to cure such breach within sixty (60) days after receipt of written notice thereof from the other Party. Further iNextLabs reserves the right to pursue any and all legal and equitable remedies for any violations. </li>
                                    <li className="TOSli para">Upon termination or expiration of this Agreement: (i) any and all payment obligations of Customer that have accrued shall become immediately due and payable; (ii) Customer shall promptly return to iNextLabs, its confidential information, the Solution, any software applications and all materials provided to and installed by the Customer pursuant to this Agreement; (iii) Customer shall uninstall the Solution installed on its systems. </li>
                                    <li className="TOSli para">The user account may be temporarily disabled in whole or part of any Service in the event of any suspected illegal activity, extended periods of inactivity or requests by law enforcement or other government agencies. Objections to suspension or disabling of user accounts should be made to (accounts@inextlabs.com) within thirty days (30) of being notified about the suspension. iNextLabs may terminate a suspended or disabled user account after thirty days. iNextLabs will also terminate the user account on the Customer’s request. </li>
                                </ol>
                            </p>    
                        </li>       
                        <li className="TOSli paragraphTitle">BRAND LICENSE
                            <p className="para">Any notice required or permitted to be given under the Agreement shall be in writing and may be given by delivering, sending by electronic facsimile transmission or other means of electronic communication capable of producing a printed copy, or sending by prepaid registered mail, the notice to the following address: </p>
                            <p className="para"><b>Office address:</b> 7500A Beach Road, #07-302, The Plaza, Singapore 199591 </p>
                            <p className="para"><b>Email:</b> accounts@inextlabs.com </p>
                        </li>                 
                        <li className="TOSli paragraphTitle">MISCELLANEOUS PROVISIONS
                            <p className="para"><b>No unlawful or prohibited use:</b> As a condition, for the use of the Services, the Customer shall not use the Services for any purpose that is unlawful or prohibited by the terms of this Agreement. The Customer may not use the Services in any manner that could damage, disable, overburden, or impair iNextLabs Website. The Customer  may not attempt to gain unauthorized access to any Services, other accounts, computer systems or networks connected to iNextLabs Website or to any of the Services, through hacking, password mining or any other means. The Customer may not obtain or attempt to obtain any materials or information through any means not intentionally made available through the Services. </p>
                            <p className="para"><b>Governing Law, Dispute Resolution and Jurisdiction:</b> This Agreement shall be governed by laws of Singapore In the event of a dispute arising out of this Agreement including any questions regarding the existence of this Agreement, validity or termination or breach (which, if capable of being remedied, has not been remedied within 30 (thirty) days of its occurrence), is  between the parties hereto, the Parties shall attempt to resolve the same amicably by mutual discussions failing which such dispute shall be referred to arbitration before a sole member tribunal, administered by the Singapore International Arbitration Centre (“SIAC”) in accordance with the Arbitration Rules of the Singapore International Arbitration Centre (“SIAC Rules”) for the time being in force, which rules are deemed to be incorporated by reference in this clause. The seat of arbitration shall be Singapore. The language of the arbitration shall be English. </p>
                            <p className="para"><b>No Agency:</b> It is clearly understood and accepted by both parties that this Agreement and the contract between the parties evidenced by it are on “principal to principal” basis and nothing herein contained shall be construed or understood as constituting either Party hereto the agent or representative of the other under any circumstances.  </p>
                            <p className="para"><b>Force Majeure:</b>  Except for the accrued payment obligations of the Customer, neither Party shall be liable for failure to fulfil any obligation under this Agreement on account of force majeure reasons or reasons beyond its reasonable control including without limitation, acts of god, pandemic, war, fire accident, storm tempests or earthquake or any governmental actions taken pursuant to the same. However, the Customer shall not be entitled to the defence of Force Majeure in relation to its obligation to pay the Charges under this Agreement.  </p>
                            <p className="para"><b>Relationship:</b> Subject to the terms and conditions of this Agreement, the Customer is and shall act as an independent contractor and nothing in the Agreement shall create or be deemed to create a partnership or relationship of principal/agent, employer/employee or joint-venture between the Parties. The Customer is not authorised to assume or create any obligation or responsibility, including, but not limited to, obligations based on warranties or guarantees or other contractual obligations. </p>
                            <p className="para"><b>Assignment:</b> This Agreement shall be binding upon and ensure to the benefit of the Parties hereto and their respective successors. Except to the extent provided herein, neither this Agreement nor any of the rights, interests or obligations hereunder shall be assigned to any third party, unless otherwise agreed in writing by both parties.  </p>
                            <p className="para"><b>Notices:</b> Any notices, requests and other communications required or permitted hereunder shall be in writing and shall be given by hand against written acknowledgement of receipt, or sent by registered mail, electronic mail or by facsimile followed by a confirmation letter by registered mail, at or to each of the parties at the addresses set forth hereinabove.  </p>
                            <p className="para"><b>Headings:</b> The headings contained in this Agreement are solely for the purpose of reference and shall not in any way affect the meaning or interpretation of this Agreement.  </p>
                            <p className="para"><b>Amendments:</b> This Agreement can be modified, supplemented, or amended only by a written agreement executed by both parties.</p>
                            <p className="para"><b>Waiver:</b> Failure of either Party to require performance of any provision of this Agreement shall not affect such Party's right to full performance thereof at any time thereafter, and any waiver by either Party of a breach of any provision hereof shall not constitute a waiver of a similar breach in the future or of any other breach. No waiver shall be effective unless in writing and duly executed by the concerned Party.  </p>
                            <p className="para"><b>Severability:</b> If any provision of this Agreement is held to be invalid, illegal, or unenforceable, such provision will be struck from the Agreement and the remaining provisions of this Agreement shall remain in full force and effect. Further, the Parties shall endeavour to replace such provision with a valid, legally enforceable provision that reflects the original intent of the parties.  </p>
                            <p className="para"><b>Third Party Rights:</b> This Agreement is made for the benefit of the Parties, and is not intended to benefit any third party or be enforceable by any third party. </p>
                            <p className="para"><b>Corrections and Changes:</b> While iNextLabs will endeavour to keep their Service and Solution up to date, iNextLabs cannot assume responsibility for any errors or omissions. iNextLabs further does not warrant the accuracy or completeness of the information, text, graphics, links or other items contained within these Solutions. iNextLabs may make changes to the Solutions or to the service offerings described herein, at any time without notice, and makes no commitment to update the information contained herein.  </p>
                            <p className="para"><b>Complete Understanding:</b> This Agreement and its annexure constitute the entire understanding between Parties relating to the subject matter hereof.  </p>
                        </li>                 
                    </ol>

                    <p className="para">END OF TERMS OF SERVICE</p>
                    <p className="para">If you have any questions or concerns regarding this Agreement, please contact us at <a href="mailto:accounts@inextlabs.com">accounts@inextlabs.com</a></p>
                </div>
            </div>
            
            <Footer />
        </div>
  )
}

export default TermsOfService
