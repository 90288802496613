import { Menu } from "antd";
import { Link } from "react-router-dom";

let keyCounter = 1;

function getNextKey() {
  return `key${keyCounter++}`;
}

function getItem(label, icon, children, type) {
  return {
    key: getNextKey(),
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem("Products", null, [
    {
      label: <Link to="/products/inflow">inFlow</Link>,
      key: getNextKey(),
    },
    {
      label: <Link to="/products/inflow-enterprise">inFlow Enterprise</Link>,
      key: getNextKey(),
    },
  ]),

  getItem("Solutions", null, [
    getItem("By Function", null, [
      {
        label: (
          <Link to="/solution/sales-and-marketing">
            Sales & Marketing Automation
          </Link>
        ),
        key: getNextKey(),
      },
      {
        label: (
          <Link to="/solution/customer-support-automation">
            Customer Support Automation
          </Link>
        ),
        key: getNextKey(),
      },
      {
        label: (
          <Link to="/solution/human-resources">
            Conversational Employee Experience
          </Link>
        ),
        key: getNextKey(),
      },
    ]),
    getItem("By Industry", null, [
      {
        label: <Link to="/solution/healthcare">Healthcare</Link>,
        key: getNextKey(),
      },
      {
        label: <Link to="/solution/bank">BFSI</Link>,
        key: getNextKey(),
      },
      {
        label: <Link to="/solution/restaurant">FnB</Link>,
        key: getNextKey(),
      },
      {
        label: <Link to="/solution/retail">Retail</Link>,
        key: getNextKey(),
      },
      {
        label: <Link to="/solution/other-industries">Other Industries</Link>,
        key: getNextKey(),
      },
    ]),
  ]),

  getItem("Resources", null, [
    {
      label: <Link to="/resources/casestudy">Case Studies</Link>,
      key: getNextKey(),
    },
    {
      label: <Link to="/resources/blog">Blogs</Link>,
      key: getNextKey(),
    },
    {
      label: <Link to="/resources/library">Library</Link>,
      key: getNextKey(),
    },
    {
      label: <Link to="/resources/webinars">Webinars</Link>,
      key: getNextKey(),
    },
  ]),

  getItem("Company", null, [
    {
      label: <Link to="/about-us">About Us</Link>,
      key: getNextKey(),
    },
    {
      label: <Link to="/career">Careers</Link>,
      key: getNextKey(),
    },
    {
      label: <Link to="/partners">Partner with Us</Link>,
      key: getNextKey(),
    },
    {
      label: <Link to="/contact">Contact</Link>,
      key: getNextKey(),
    },
    {
      label: <Link to="/news">Events & News</Link>,
      key: getNextKey(),
    },
  ]),
];

const HeaderBurgerMenuItems = () => {
  return (
    <>
      <div className="generative-ai-nav">
        <Link to="/generative-ai">Generative AI</Link>
      </div>
      <Menu
        style={{
          width: "100%",
        }}
        defaultSelectedKeys={[items[0].children[0].key]} 
        mode="inline"
        items={items}
      />
    </>
  );
};
export default HeaderBurgerMenuItems;
