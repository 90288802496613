import React, { useState } from "react";
import Filler from "../header/Filler";
import HomeBanner from "../banner/HomeBanner";
import { homeContent } from "./HomeContent";
import ValueProps from "../swimlinesection/ValueProps";
import Header from "../header/Header";
import TestiMonial from "../testimonial/TestiMonial";
import Features from "../features/Features";
import Articles from "../articles/Articles";
import Footer from "../footer/Footer";
import Clients from "../clients/Clients";
import { FloatButton } from "antd";
import PromoVideo from "../promo-video/PromoVideo";
import Meta from "../Meta";
import DiscoveryWorkshop from "../discovery-workshop/DiscoveryWorkshop";
import BuisnessStatement from "../statement/BuisnessStatement";
// import WebinarModal from "../modal/WebinarModal"; // Add this import statement

const Home = () => {
  // const [isWebinarModalOpen, setIsWebinarModalOpen] = useState(false); // Set initial state as needed
  return (
    <>
      <Meta
        title={homeContent.meta.title}
        description={homeContent.meta.description}
        image={homeContent.meta.image}
        locale={homeContent.meta.locale}
        siteName={homeContent.meta.siteName}
        type={homeContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <HomeBanner content={homeContent.banner} />
      <PromoVideo videoURL="https://inextlabswebassets.blob.core.windows.net/video/inextlabs-branding-50.mp4" />
      {/* <WebinarModal
        isOpen={isWebinarModalOpen}
        setIsOpen={setIsWebinarModalOpen}
      /> */}
      <BuisnessStatement />
      <ValueProps content={homeContent.valueProps} />
      <DiscoveryWorkshop />
      <TestiMonial content={homeContent.testimonial} />
      <Features content={homeContent.features} />
      <Clients content={homeContent.clients} />
      <Articles content={homeContent.articles} />
      <Footer />
      <FloatButton.BackTop />
    </>
  );
};

export default Home;