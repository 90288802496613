import React from "react";
import Filler from "../../header/Filler";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import { homeContent } from "../../home/HomeContent";
import Clients from "../../clients/Clients";
import { bankContent } from "./bankContent";
import InnerPageValueProps from "../../innerpageswimlane/InnerPageValueProps";
import Review from "../../review/Review";
import Meta from "../../Meta";
import SolutionBanner from "../banner/SolutionBanner";
import CustomerStories from "../customer-stories/CustomerStories";
import { resourcesContent } from "../../resources/resourcesContent";
import CounterStats from "../../stats-counter/Stats-counter";
import ValueProposition from "../../value-proposition/ValueProposition";
import IntegrationSection from "../integration/IntegrationSection";
import WebinarSection from "../webinar/WebinarSection";
import AccordionSection from "../../accordion/Accordion";
const Bank = () => {
  return (
    <>
      <Meta
        title={bankContent.meta.title}
        description={bankContent.meta.description}
        image={bankContent.meta.image}
        locale={bankContent.meta.locale}
        siteName={bankContent.meta.siteName}
        type={bankContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <SolutionBanner content={bankContent.banner} />
      <CounterStats content={bankContent.stats} />
      <InnerPageValueProps content={bankContent.valueProps} />
      <ValueProposition content={bankContent.ValueProposition} />
      {/* <IntegrationSection content={bankContent.integrations} /> */}
      <CustomerStories/>
      {/* <WebinarSection /> */}
      <AccordionSection content={bankContent.FAQ} />
      <Footer />
    </>
  );
};

export default Bank;
