import React, { useEffect, useState } from "react";
import ContactForm from "../contact/form/ContactForm";
import { Link } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { BiSolidUser } from "react-icons/bi";
import moment from "moment";
import { FaRegCalendar } from "react-icons/fa";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import { BsCalendarEvent } from "react-icons/bs";
import { Divider } from "antd";

const NewsDetailsItems = (props) => {
  const [copied, setCopied] = useState(false);
  const currentUrl = window.location.href;
  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 30000); // 60 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount or copied state change
    }
  }, [copied]);
  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <div className="resource-details-items-container">
            <div className="resource-details-items-content-container">
              <div className="resource-details-items-content-image">
                <img src={props.content.imageURL} alt="news cover image" />
              </div>
              <div className="resource-details-items-content">
                <div className="resource-details-items-content-header">
                  <div className="resource-details-items-content-title">
                    {props.content.title}
                  </div>
                  <div className="resource-details-items-info">
                    <div className="resource-details-items-info-type">
                      <BsCalendarEvent size={12} />
                      {props.content.type}
                    </div>
                    <Divider type="vertical" />
                    <div className="resource-details-items-info-date">
                      <FaRegCalendar size={15} />
                      {props.content.date}
                    </div>
                    <Divider type="vertical" />

                    {props.content.author && (
                      <div className="resource-details-items-info-author">
                        <BiSolidUser size={15} />
                        {props.content.author}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div
                    dangerouslySetInnerHTML={{ __html: props.content.content }}
                    className="resource-details-items-content-data"
                  ></div>
                  {props.content.registerUrl && (
                    <div className="resource-details-items-content-button button">
                      <Link to={props.content.registerUrl} target="blank">
                        <button className="button">Register Now</button>
                      </Link>
                    </div>
                  )}
                </div>

                <Divider />

                <div className="resource-details-items-content-social-share">
                  Share this article:
                  <FacebookShareButton
                    url={currentUrl}
                    quote={props.content.title}
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={currentUrl}
                    title={props.content.title}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <LinkedinShareButton
                    url={currentUrl}
                    title={props.content.title}
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                  <WhatsappShareButton
                    url={currentUrl}
                    title={props.content.title}
                    separator=":: "
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </div>
                <div className="copy-link-container">
                  <input value={currentUrl} />
                  <CopyToClipboard
                    text={currentUrl}
                    onCopy={() => setCopied(true)}
                  >
                    <Button type="primary">
                      {!copied ? "Copy" : "Copied"}
                    </Button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>

            {/* <div className="resource-details-items-sidebar">
              <div className="contact-details-container-form-container">
                <div className="contact-details-container-form-container-main-title sectionMainTitle">
                  Get In Touch
                </div>
                <div className="contact-details-container-form-container-title">
                  Talk to an expert
                </div>
                <div className="contact-details-container-form-container-description para">
                  Interested in discovering how iNextLabs Generative-AI
                  solutions can elevate your business? Fill out the form below
                  to connect with an expert and explore the possibilities.
                </div>
                <ContactForm />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsDetailsItems;
